import {Component, Input, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProgressComponent} from '@app/core/feature-modules/progress/progress.component';
import {SuccessComponent} from '@app/gbp-management/gbp-tab-post/components/gbp-post-create/success/success.component';
import {GBPService} from '@app/gbp-management/gbp.service';
import RZLib from '@app/core/backend-adapter/db2/RZ-lib.helpers';
import { GBPPhotoConfigService } from '../../../services/gbp-photo-config-service/gbp-photo-config-service';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';

export interface IMediaSaveObject {
  account_id: number;
  category: string;
  internalURL: string;
  sourceURL: string;
  location_ids: [];
  scheduleTime: any;
  scheduleTimeZone: number;
  _status: string;
  _id?: number;
}
@Component({
  selector: 'app-gbp-photo-upload',
  templateUrl: './gbp-photo-upload.component.html',
  styleUrls: ['./gbp-photo-upload.component.scss'],
})
export class GbpPhotoUploadComponent implements OnInit {
  @Input() rowData;

  stepsLabels = ['Upload Media', 'Schedule and Select Locations', 'Review and Upload'];
  activeIndex = 0;
  photoForm = new FormGroup({
    upload: new FormGroup({
      imageUrl: new FormControl(''),
    }),
    photoAttrs: new FormGroup({
      description: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
    }),
    schedule: new FormGroup({
      checked: new FormControl(false),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      timeZone: new FormControl('', Validators.required),
      location: new FormControl(''),
      isSelectedAll: new FormControl(''),
    }),
  });
  uploadGroup: FormGroup;
  photoAttrsGroup: FormGroup;
  scheduleGroup: FormGroup;
  formGroupName = 'upload';
  saveObj;
  selectedMediaFile: string = '';
  public saveButtonLabel: string = 'Save and Next';
  public selectedMediaCategory: any;
  public mediaRequirements: string[] = [];
  public mediaRequirementsTitle: string;
  public preSelectedLocation: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private gbpService: GBPService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private gBPPhotoConfigService: GBPPhotoConfigService,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.selectedMediaCategory = this.dynamicDialogConfig.data['selectedMedia'];
    this.uploadGroup = this.photoForm.controls.upload as FormGroup;
    this.photoAttrsGroup = this.photoForm.controls.photoAttrs as FormGroup;
    this.scheduleGroup = this.photoForm.controls.schedule as FormGroup;
    if (this.dynamicDialogConfig.data['data']) {
      this.initGBPPhotoEdit(this.dynamicDialogConfig.data['data']);
    }
    this.initMediaCategoryType();
    this.validationsRequirements();
  }

  private initMediaCategoryType(): void {
    switch (this.selectedMediaCategory['category']) {
      case 'PHOTO':
        this.photoAttrsGroup.controls['category'].patchValue('ADDITIONAL');
        break;
      case 'PHOTO INTERIOR':
        this.photoAttrsGroup.controls['category'].patchValue('INTERIOR');
        break;
      case 'PHOTO EXTERIOR':
        this.photoAttrsGroup.controls['category'].patchValue('EXTERIOR');
        break;
      case 'VIDEO':
        this.photoAttrsGroup.controls['category'].patchValue('ADDITIONAL');
        break;
      case 'COVER PHOTO':
        this.photoAttrsGroup.controls['category'].patchValue('COVER');
        break;
      case 'LOGO':
        this.photoAttrsGroup.controls['category'].patchValue('LOGO');
        break;
    };
  }

  private validationsRequirements(): void {
    this.mediaRequirements = this.gBPPhotoConfigService.setGBPMediaRequirements(
      this.selectedMediaCategory['category']
    ).description;
    this.mediaRequirementsTitle = this.gBPPhotoConfigService.setGBPMediaRequirements(
      this.selectedMediaCategory['category']
    ).title;
  }

  private preparePhotoSaveObject(photoAttrsGroup, scheduleGroup, uploadGroup) {
    const tempObj: IMediaSaveObject = {
      account_id: 0,
      internalURL: '',
      sourceURL: '',
      category: '',
      location_ids: [],
      scheduleTime: new RZLib.Timestamp().setNow(),
      scheduleTimeZone: 0,
      _status: 'AP',
    };

    tempObj.account_id = this.gbpService.account_id;
    tempObj.category = photoAttrsGroup.category;
    const urlFieldName = new RegExp(/^https:\/\/rseo-platform(-dev)?.s3.amazonaws.com\/media\/.+/i).test(
      uploadGroup.imageUrl
    )
      ? 'internalURL'
      : 'sourceURL';
    tempObj[urlFieldName] = uploadGroup.imageUrl;

    tempObj.location_ids = scheduleGroup.isSelectedAll
      ? '*'
      : scheduleGroup.location?.length > 0
      ? scheduleGroup.location.map((l) => l[0])
      : null;
    tempObj.scheduleTime = scheduleGroup.checked
      ? this.gbpService.prepareDate(scheduleGroup.date, tempObj.scheduleTime, scheduleGroup.time)
      : '';
    tempObj.scheduleTimeZone = !scheduleGroup.timeZone ? null : scheduleGroup.timeZone;

    return tempObj;
  }

  goNext(progress: ProgressComponent) {
    progress.next();
  }

  onStateChange(event) {
    if (event.status == 'completed') {
      // send data to backend

      this.saveObj = this.preparePhotoSaveObject(
        this.photoAttrsGroup.value,
        this.scheduleGroup.value,
        this.uploadGroup.value
      );

      this.gbpService.updatePhotoBatch(this.saveObj);
      this.ref.close();
      this.dialogService.open(SuccessComponent, {
        width: '20%',
      });
    }
    this.activeIndex = event.activeIndex;
    switch (this.activeIndex) {
      case 0: {
        this.formGroupName = 'upload';
        break;
      }
      case 1: {
        this.formGroupName = 'photoAttrs';
        break;
      }
      case 2: {
        this.saveButtonLabel = 'Save';
        this.formGroupName = 'schedule';
        break;
      }
    }
  }

  closeDialog() {
    this.ref.close();
  }

  imageChanged(url) {
    this.selectedMediaFile = url[0];
    this.photoForm.patchValue({ upload: { imageUrl: url[0] } });
  }

  public goBack(): void {
    switch (this.activeIndex) {
      case 0: {
        this.saveButtonLabel = 'Save and Next';
        break;
      }
      case 1: {
        this.saveButtonLabel = 'Save and Next';
        break;
      }
      case 2: {
        this.saveButtonLabel = 'Save';
        break;
      }
    }
  }

  // To disable Save and Next button if no media file or custom url is entered.
  get checkMediaUploadStatus(): boolean {
    return this.selectedMediaFile ? false : true;
  }

  get checkDateAndLocStatus(): boolean {
    const isChecked = this.scheduleGroup.controls;
    let dateSelection: boolean;
    if (!isChecked['checked'].value) {
      return (dateSelection =
        !isChecked['checked'].value &&
        (isChecked['location'].value.length ||
          isChecked['isSelectedAll'].value ||
          isChecked['preSelectedLocation']?.value?.length)
          ? false
          : true);
    } else {
      return (dateSelection =
        isChecked['date'].value &&
        isChecked['time'].value &&
        isChecked['timeZone'].value &&
        (isChecked['location'].value.length ||
          isChecked['isSelectedAll'].value ||
          isChecked['preSelectedLocation']?.value?.length)
          ? false
          : true);
    }
  }

  initGBPPhotoEdit(gbpPhoto): void {
    // This endpoint should be different for photos location...
    this.socketService
      .sendRequest('get-media-detail-coll', { detailLevel: 3, where: ['-and', `batch_id=${gbpPhoto._id}`] })
      .then((res) => {
        res['collection'].forEach((l) => {
          this.preSelectedLocation.push({
            _id: l.location_id,
            storeCode: l.storeCode,
            businessName: l.businessName,
            city: l.city,
            state: l.state,
            disabled: true,
          });
        });
        this.scheduleGroup.addControl('preSelectedLocation', new FormControl([...this.preSelectedLocation]));
      });

    this.selectedMediaFile = gbpPhoto.internalURL;
    this.uploadGroup.patchValue({
      imageUrl: gbpPhoto.internalURL || gbpPhoto?.sourceURL,
    });

    const scheduledTime =
      gbpPhoto.scheduleTime !== null
        ? new Date(
            new Date().getDate(),
            new Date().getMonth() + 1,
            new Date().getFullYear(),
            gbpPhoto.scheduleTime?._hour_,
            gbpPhoto.scheduleTime?._minute_,
            gbpPhoto.scheduleTime?._second_
          )
        : '';
    const scheduledDate =
      gbpPhoto.scheduleTime !== null
        ? new Date(`${gbpPhoto.scheduleTime?._month_}/${gbpPhoto.scheduleTime?._day_}/${gbpPhoto.scheduleTime?._year_}`)
        : '';
    this.scheduleGroup.patchValue({
      date: scheduledDate,
      time: scheduledTime,
      timeZone: gbpPhoto?.scheduleTimeZone,
    });
  }
}
