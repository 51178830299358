<div class="surveys">
  <div class="surveys_header">
    <p class="surveys_header__title">Feedback survey.</p>
    <p-splitButton class="surveys_header__export" #actions label="Download report" styleClass="export-button"
      [model]="actionItems" (onClick)="actions.onDropdownButtonClick(null)">
    </p-splitButton>
  </div>

  <div class="surveys_stats" *ngIf="statisticsList.length">
    <div *ngFor="let stats of statisticsList">
      <p>Account Name: {{ stats.account_ident }}</p>
      <p>Average Rating: {{ stats.Average_rating }}</p>
      <p>Total surveys submitted: {{ stats.Total_ratings }}</p>
      <p>Total positive rating: {{ stats.Total_positive_ratings }}</p>
      <p>Total neutral rating: {{ stats.Total_neutral_ratings }}</p>
      <p>Total negative rating: {{ stats.Total_negative_ratings }}</p>
    </div>
  </div>

  <div class="surveys_list">
    <p-table #dt [value]="feedbackSurveysList" [loading]="isLoadingData" [paginator]="true" [rows]="100" [first]="first"
      [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onPage)="pageChange($event)"
      sortMode="multiple" sortOrder="-1" [globalFilterFields]="['updated_at']" (onLazyLoad)="lazyLoadLocations($event)" [lazy]="true"
      [totalRecords]="totalRecords" [lazyLoadOnInit]="true">

      <ng-template pTemplate="header">
        <tr>
          <th>Type</th>
          <th>Store code</th>
          <th>Address</th>
          <th pSortableColumn="date">Date <p-sortIcon field="updated_at"></p-sortIcon></th>
          <th class="check_number">Check number</th>
          <th class="rating">Rating</th>
          <th class="comments">Comments</th>
          <th class="contact">Contact</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data?.type }}</td>
          <td>{{ data?.storecode }}</td>
          <td>{{ data?.address }}</td>
          <td>{{ data?.date }}</td>
          <td>{{ data?.check_number }}</td>
          <td>{{ data?.rating }}</td>
          <td  class="comments">{{ data?.comment }}</td>
          <td>{{ data?.contact || "N/A" }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
