<div class="reviews-container">

<div class="search">
  <input class="search_input" pInputText type="text" placeholder="Search for ratings by review and response..."
    [(ngModel)]="keyword" (keyup)="userSearch()" />

  <button pButton type="button" icon="fa fa-filter" class="search_filter"
    [ngStyle]="chipsArray.length > 0 && { color: SYSTEM_COLORS.$systemGreen }" (click)="op.toggle($event)">
  </button>

  <p-overlayPanel #op>
    <ng-template pTemplate>
      <div class="search-filter">
        <app-table-filter [data]="filterData" (filterData)="onChipsRefresh($event)"></app-table-filter>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>

<p class="reviews_date">
  <b>Reviews from {{ startDate }} to {{ endDate }}</b>
</p>

<div class="chips-bar" *ngIf="chipsArray.length">
  <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
</div>
  <div class="dashboard">
    <!-- Reviews & Ratings tab -->
    <div class="view-reply">
      <!-- Graphs card -->
      <app-overview-card
        [reviewsData]="reviewsData"
        [dailyAvgRatings]="dailyAvgRatings"
        [dailyTotalReviews]="dailyTotalReviews"
        (hide)="hideOverview($event)"
      ></app-overview-card>

      <!-- Reviews table -->
      <div class="table-container">
        <p-table
          #reviewTbl
          class="reviews-table"
          [value]="reviewData"
          dataKey="_id"
          [globalFilterFields]="['reviewerComment', 'businessName', 'storeCode', 'replyComment']"
          [(selection)]="selectedReviews"
          [lazy]="true"
          [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
          (onLazyLoad)="lazyLoadLocations($event)"
          [rows]="pageSize"
          [totalRecords]="totalReviewsCount"
          [showCurrentPageReport]="true"
          [columns]="cols"
          sortField="reviewCreatedAt"
          [sortOrder]="-1"
          [lazyLoadOnInit]="true"
        >
          <!-- [loading]="loading" -->
          <ng-template pTemplate="caption">
            <div class="flex-search">
              <div class="review-search">
                <span class="p-input-icon-right">
                  <i class="pi pi-search"></i>
                  <span class="p-inputgroup">
                    <input
                      class="input"
                      pInputText
                      type="text"
                      placeholder="Search for ratings by location, by name, store code, address, keyword, etc…"
                      [(ngModel)]="keyword"
                      (input)="reviewTbl.filterGlobal($event.target.value, 'contains')"
                    />
                    <button
                      pButton
                      type="button"
                      icon="fa fa-filter"
                      class="filter-button"
                      [ngStyle]="chipsArray.length > 0 && { color: SYSTEM_COLORS.$systemGreen }"
                      (click)="op.toggle($event)"
                    ></button>
                    <p-overlayPanel #op>
                      <ng-template pTemplate>
                        <div class="search-filter">
                          <app-table-filter
                            [data]="filterData"
                            (filterData)="onChipsRefresh($event)"
                          ></app-table-filter>
                        </div>
                      </ng-template>
                    </p-overlayPanel>
                  </span>
                </span>
              </div>

              <div class="button-container">
                <button
                  pButton
                  type="button"
                  label="ADD/EDIT RESPONSE"
                  class="p-button-outlined"
                  [disabled]="selectedReviews.length == 0"
                  (click)="addMultiReply()"
                ></button>
                <p-splitButton
                  #actions
                  label="EXPORT"
                  styleClass="export-button"
                  [model]="actionItems"
                  (onClick)="actions.onDropdownButtonClick(null)">
                </p-splitButton>
              </div>
            </div>
            <div class="chips-bar">
              <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="select">
                <p-checkbox [(ngModel)]="selectAllReviews" [binary]="true" (onChange)="handleSelectAllReviews($event)"></p-checkbox>
                <span>SELECT</span>
              </th>
              <th class="directory" pSortableColumn="directory">
                DIRECTORY
              </th>
              <th class="storecode" pSortableColumn="storeCode">
                STORE CODE
                <p-sortIcon field="storeCode"></p-sortIcon>
              </th>
              <th class="location-name" pSortableColumn="businessName">
                LOCATION
                <p-sortIcon field="businessName"></p-sortIcon>
              </th>
              <th class="review" pSortableColumn="reviewUpdatedAt">
                REVIEW
                <p-sortIcon field="reviewerComment"></p-sortIcon>
              </th>
              <th class="rating" pSortableColumn="starRating">
                RATING
                <p-sortIcon field="starRating"></p-sortIcon>
              </th>
              <th class="response" pSortableColumn="replyComment">
                RESPONSE
                <p-sortIcon field="replyComment"></p-sortIcon>
              </th>
              <th class="add-edit"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="select">
                <p-checkbox
                  class="review-table"
                  name="reviews"
                  [value]="rowData"
                  [(ngModel)]="selectedReviews"
                  (onChange)="onRowSelect($event)"
                ></p-checkbox>
              </td>
              <td class="directory">
                <img src="assets/directories/round/icon-yelp.png" alt="directory logo" style="height: 40px" />
              </td>
              <td class="store-code">{{ rowData.storeCode }}</td>
              <td class="location-name">
                {{ rowData.businessName }}<br />
                {{ rowData.addressLines }}<br />
                {{ rowData.city }}, {{ rowData.state }} {{ rowData.postalCode }}
              </td>
              <td class="review review-row">
                <p *ngIf="rowData.reviewUpdatedAt || rowData.reviewUpdatedAt !== null; else dash">
                  On {{ rowData.reviewUpdatedAt | truncteReviewsDate }}
                </p>
                <span
                  [pTooltip]="rowData.reviewerComment ? rowData.reviewTooltip : null"
                  tooltipPosition="bottom"
                  tooltipStyleClass="review-tooltip"
                  *ngIf="rowData.reviewerComment; else dash"
                  class="review-response"
                >
                  {{ rowData.reviewerComment }}
                </span>
              </td>
              <td class="rating">
                <span class="rating-digit">{{ rowData.starRating }} </span>
                <span class="fa fa-star"></span>
              </td>
              <td class="response response-row">
                  <span *ngIf="rowData.replyUpdatedAt || rowData.replyUpdatedAt !== null">
                    On {{
                    rowData.replyUpdatedAt._year_ +
                    '/' +
                    rowData.replyUpdatedAt._month_ +
                    '/' +
                    rowData.replyUpdatedAt._day_
                    }}
                  </span>
                  <span *ngIf="rowData.replyComment" class="review-response">
                    {{ rowData.replyComment }}
                  </span>
                  <!-- <button class="oauth-button" (click)="publishYelp()">Respond</button> -->
              </td>
              <td class="add-edit">
                <ul class="table-actions-list">
                  <li *ngIf="sessionService.hasPerm('review', 're')" class="edit-reply" (click)="openEditResponse(rowData)">
                    <i class="pi pi-comments" [pTooltip]="'Respond'" tooltipPosition="left"></i>
                    <!-- (click)="openEditResponse(rowData)" -->
                  </li>
                  <li class="" (click)="copyReviewToClipboard(rowData)"><i
                    class="pi pi-copy action_icon" [pTooltip]="'Copy Review content to clipboard'" tooltipPosition="left"></i>
                  </li>
                  <!-- <li *ngIf="!rowData.replyUpdatedAt" class="edit-reply disable-btn"><i
                    class="pi pi-comments" [pTooltip]="'Coming soon!'" tooltipPosition="left"></i>
                    (click)="openEditResponse(rowData)"
                  </li>
                  <li *ngIf="rowData.replyUpdatedAt" class="delete-reply disable-btn"><i
                    class="pi pi-trash" [pTooltip]="'Coming soon!'" tooltipPosition="left"></i>
                    (click)="openDeleteResponse(rowData)"
                  </li> -->
                  <li class="delete-reply" (click)="deleteReview(rowData)"><i
                    class="pi pi-trash action_icon" [pTooltip]="'Delete Review'" tooltipPosition="left"></i>
                  </li>

                </ul>
              </td>
              <ng-template #dash>
                <img src="assets/horizontal_rule.png" width="16px" />
              </ng-template>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- Multi-reply popup -->
<p-dialog
  class="reviews-multi-response"
  [(visible)]="showMultiReplyPopup"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="false"
  (onHide)="cancelMultiReply()"
>
  <ng-template pTemplate="content">
    <app-multi-reply [selectedReviews]="selectedReviews" (closeDialog)="getInfoFromDialog($event)"></app-multi-reply>
  </ng-template>
</p-dialog>
