import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Accordion } from 'primeng/accordion';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { SocketService } from '../../core/backend-adapter/socket.service';
import { BroadcastService } from '../../core/backend-adapter/broadcast.service';
import { SessionService } from '../../core/backend-adapter/session.service';
import { DBService } from '../../core/backend-adapter/db.service';
import { Debounce } from 'typescript-debounce';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterButtonComponent } from '../shared/components/filter-buttons/filter-buttons.component';
import { AppleMapsInsightsDataService } from '../shared/services/apple-maps-insights-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapsInsightsConfigService } from '../shared/services/apple-maps-graph-config.service';
import { ReviewsService } from 'src/app/review-management/reviews.service';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { ConvertCSVToJSONService } from '../shared/services/convert-csv-to-json.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { ViewGraphReportsService } from '../shared/services/view-graph-reports.service';
import { AdvanceFiltersService } from '../shared/services/advance-filters.service';

@Component({
  selector: 'app-google-dashboard-tab',
  templateUrl: './google-dashboard-tab.component.html',
  styleUrls: ['./google-dashboard-tab.component.scss'],
})
export class GoogleDashboardTabComponent implements OnInit {
  //// dashboard view
  showReviews = true;
  showReviewsOnly = false;
  insightsErr = false;
  reviewsErr = false;

  // Insight charts
  public totalClicks: any = {};
  public totalImpressions: unknown = {};
  public businessConversations: unknown = {};
  public totalDirections: unknown = {};
  public bookings: unknown = {};
  public foodOrders: unknown = {};
  public combinedViews: unknown = {};
  public foodMenuClicks: unknown = {};

  public stackOptions;
  public viewsDiff: string = '';
  public clicksDiff: string = '';
  public impressionsDiff: string = '';
  public yearlyViewsDiff: string = '';
  public yearlyClicksDiff: string = '';
  public yearlyImpressionsDiff: string = '';
  stretchGraphBckgd = false;
  lastPrefElem: string;
  ratingDiff = '';
  unrespondedPct: number;
  respondedPct: number;
  faCircle = faCircle;
  //// date range dropdown
  dateRanges = [
    { name: 'Last 3 months', value: { timeunit: 'month', periods: 3 } },
    { name: 'Quarterly comparison', value: { timeunit: 'quarter', periods: 4 } },
    { name: 'Last 6 months', value: { timeunit: 'month', periods: 6 } },
    { name: 'Last 12 months', value: { timeunit: 'month', periods: 12 } },
    { name: 'Last 24 months', value: { timeunit: 'month', periods: 24 } },
  ];

  selectedRange = { timeunit: 'month', periods: 3 };

  //// Customize Insights Selection popup
  showEditPopup = false;
  selectedInsightPrefs: string[] = [
    'totalClicks',
    'totalImpressions',
    'businessConversations',
    'totalDirections',
    'bookings',
    'foodOrders',
    'combinedViews',
    'foodMenuClicks',
    'phoneCalls',
    'websiteClicks',
    'mobileSearchImpressions',
    'desktopSearchImpressions',
    'mobileMapsImpressions',
    'desktopMapsImpressions',
    'websiteClicks',
  ];
  disabledField: string;
  prevInsightPrefs: string[]; // used to easily restore previous state when canceling popup

  //// Download selection popup
  showDwnldPopup = false;
  reportType: string;
  ////

  //// generated report view
  reportView = false;
  ////

  //// generate report button
  actionItems: MenuItem[] = [
    {
      label: 'Comparison Report',
      command: (event) => {
        this.dropdownClicked(event.item);
      },
    },
    {
      label: 'Review Report',
      command: (event) => {
        this.dropdownClicked(event.item);
      },
    },
    {
      label: 'Keyword Sentiment Report',
      command: (event) => {
        this.dropdownClicked(event.item);
      },
    },
    {
      label: 'GBP Report',
      command: (event) => {
        this.dropdownClicked(event.item);
      },
    },
  ];
  showOptPopup = false;
  noAcct: boolean;
  /* comparison report pop-up */
  reportName: string;
  /* reportName: string = 'Rep1' */
  aggregateBy: string;
  compareBy: string;
  missingOptions = true;
  missingReportName = false;
  /* Generate Report pop-up */
  showGenRepDialog = false;
  locsToCompare: number[] = [1, 2];
  startDate: Date;
  endDate: Date;
  dateViews: any[] = [
    { name: 'Weekly View (only for date ranges under 1 month)', code: 'weekly' },
    { name: 'Monthly View', code: 'monthly' },
    { name: 'Quarterly View', code: 'quarterly' },
    { name: 'Aggregate View', code: 'aggregate' },
  ];
  selectedView: any;
  insightsCriteria: any[] = [
    { name: 'Map Views', code: 'mapViews', selected: false },
    { name: 'Search Views', code: 'searchViews', selected: false },
    { name: 'Direct Searches', code: 'directSearches', selected: false },
    { name: 'Brand Discovery Searches', code: 'brandSearches', selected: false },
    { name: 'GBP Post Views', code: 'gbpPostViews', selected: false },
    { name: 'Click to Phone Call', code: 'clickPhone', selected: false },
    { name: 'Click to Driving Directions', code: 'clickDirections', selected: false },
    { name: 'Click to Website', code: 'clickWebsite', selected: false },
    { name: 'Photo Views', code: 'photoViews', selected: false },
    { name: 'GBP Post Clicks', code: 'gbpPostClicks', selected: false },
  ];
  selInsightsCrit: any[] = [];
  reviewCriteria: any[] = [{ name: 'Total Number of Reviews', code: 'totalReviews', selected: false }];
  selReviewCrit: any[] = [];
  missingFields = true;
  locSearchText: string;
  locSearchResults: any[];

  /// Report View
  locationsList: any = {};
  generatedTimestamp: any;
  dateRange: string;
  locListLen: number;
  reviewsTableData: any[] = [];
  insightsTableData: any[] = [];
  reviewsHeaders: string[] = [];
  insightsHeaders: string[] = [];
  graphColors: string[] = ['#FFAB0C', '#007EFF', '#8a3ffc', '#791e6c', '#FC3B00'];
  googleInsights: any = {};
  reviewInsights: any = {};

  @ViewChild('insightsAccordion') insightsAccordion: Accordion;
  @ViewChild('reviewsAccordion') reviewsAccordion: Accordion;

  //// Working on it popup
  showProgressDialog = false;
  public tags = [
    {
      label: 'Store code filter',
      disabled: false,
      command: () => {
        this.primaryFilters();
      },
    },
    {
      label: 'Advanced filters',
      disabled: false,
      command: () => {
        this.advanceFilters();
      },
    },
  ];
  public componentRef: DynamicDialogRef | undefined;
  private allAdvanceFilters: any[] = [];
  private ngUnsubscribe$ = new Subject();
  public whereClauss: any[] = [];
  private operandType: string = '-or';
  private accountId: number;
  private userId: number;

  public popupData = [
    {
      id: 'totalClicks',
      label: 'Total Clicks',
      value: 'totalClicks',
    },
    {
      id: 'totalImpressions',
      label: 'Total Impressions',
      value: 'totalImpressions',
    },
    {
      id: 'totalDirections',
      label: 'Directions',
      value: 'totalDirections',
    },
    {
      id: 'phoneCalls',
      label: 'Phone Calls',
      value: 'phoneCalls',
    },
    {
      id: 'websiteClicks',
      label: 'Website Clicks',
      value: 'websiteClicks',
    },
    {
      id: 'businessConversations',
      label: 'Total Conversations',
      value: 'businessConversations',
    },
    {
      id: 'bookings',
      label: 'Bookings',
      value: 'bookings',
    },
    {
      id: 'foodOrders',
      label: 'Food Orders',
      value: 'foodOrders',
    },
    {
      id: 'foodMenuClicks',
      label: 'Food Menu Clicks',
      value: 'foodMenuClicks',
    },
    {
      id: 'mobileSearchImpressions',
      label: 'Mobile Search Impressions',
      value: 'mobileSearchImpressions',
    },
    {
      id: 'desktopSearchImpressions',
      label: 'Desktop Search Impressions',
      value: 'desktopSearchImpressions',
    },
    {
      id: 'mobileMapsImpressions',
      label: 'Mobile Maps Impressions',
      value: 'mobileMapsImpressions',
    },
    {
      id: 'desktopMapsImpressions',
      label: 'Desktop Maps Impressions',
      value: 'desktopMapsImpressions',
    },
    {
      id: 'combinedViews',
      label: 'Combined Views',
      value: 'combinedViews',
    },
  ];

  public demoData = [];
  public fullWidth: boolean = false;

  constructor(
    public socketService: SocketService,
    public sessionService: SessionService,
    private broadcastService: BroadcastService,
    private dbService: DBService,
    private dialogService: DialogService,
    private appleMapsInsightsDataService: AppleMapsInsightsDataService,
    private mapsInsightsConfigService: MapsInsightsConfigService,
    private reviewsService: ReviewsService,
    private convertCSVToJSONService: ConvertCSVToJSONService,
    private notifyService: NotifyService,
    private viewGraphReportsService: ViewGraphReportsService,
    private advanceFiltersService: AdvanceFiltersService
  ) {}

  ngOnInit(): void {
    this.getCurrentAccountDetails();
    this.getCurrentUserDetails();

    this.stackOptions = this.mapsInsightsConfigService.stackOptions;

    Chart.register(ChartDataLabels); // needed for charts to build correctly

    this.broadcastService.subscribe('accountChanged', (item) => {
      this.insightsErr = false;
      this.loadData();
    });

    this.selectedInsightPrefs = this.showReviews
      ? !this.showReviewsOnly
        ? ['totalClicks', 'totalImpressions', 'totalDirections', 'phoneCalls', 'websiteClicks']
        : ['reviews']
      : [
          'totalClicks',
          'totalImpressions',
          'businessConversations',
          // 'totalDirections',
          'bookings',
          'foodOrders',
          'phoneCalls',
          'websiteClicks',
          'mobileSearchImpressions',
          'desktopSearchImpressions',
          'mobileMapsImpressions',
          'desktopMapsImpressions',
          'combinedViews',
        ];

    this.noAcct = !this.sessionService.currAccount;
    this.getFiltersOperandStatus();
    this.getMetricsDataOnFilterUpdates();
    this.getAdvanceFilters();
  }

  /**
   * @description To get lits of advances filters based on user's authorization.
   */
  private getAdvanceFilters(): void {
    this.advanceFiltersService.getAdvanceFiltersLists(this.accountId, this.userId).subscribe((res) => {
      this.allAdvanceFilters = this.appleMapsInsightsDataService.setAdvanceFiltersData().map((el) => {
        return {
          ...el,
          checkboxList:
            res[el.id] && res[el.id]?.length
              ? res[el.id].map((item) => {
                  return {
                    label: item,
                  };
                })
              : [],
        };
      });
    });
  }

  trackIndex(index, item) {
    return index;
  }

  @Debounce({ millisecondsDelay: 500 })
  loadData(): void {
    this.loadInsightsData({ timeunit: 'month', periods: 3 });
    // this.loadTrends();
  }

  //// Download reports
  public downloadReport(aggregation: string): void {
    console.log(
      'Download triggered for ',
      this.reportType,
      '. Range: ',
      this.selectedRange,
      ', aggregate by: ',
      aggregation
    );
  }

  triggerDwnldPopup(rprtType: string) {
    this.reportType = rprtType;
    this.showDwnldPopup = true;
  }

  cancelDwnldPopup() {
    this.showDwnldPopup = false;
  }
  ////

  //// generate reports button
  dropdownClicked(item) {
    console.log('gen rep item:', item);
    if (item.label == 'Comparison Report') {
      this.resetOptPopup();
      this.showOptPopup = true;
    }
  }
  ////

  //// comparison report pop-up
  checkForMissingOptions() {
    if (this.reportName && this.aggregateBy && this.compareBy) {
      this.missingOptions = false;
      this.missingReportName = false;
    }
    if (!this.reportName && this.aggregateBy && this.compareBy) {
      this.missingReportName = true;
      this.missingOptions = true;
    }
  }

  nextStep() {
    console.log('name:', this.reportName, 'aggregateBy:', this.aggregateBy, 'compareBy:', this.compareBy);
    this.showOptPopup = false;
    this.showGenRepDialog = true;
    this.insightsAccordion.tabs[0].selected = false;
    this.reviewsAccordion.tabs[0].selected = false;
  }

  resetOptPopup() {
    this.reportName = '';
    this.aggregateBy = '';
    this.compareBy = '';
  }
  ////

  //// Generate Report pop-up
  insertInput() {
    if (this.locsToCompare.length <= 4) {
      this.locsToCompare.push(this.locsToCompare.length + 1);
    }
  }

  resetGenRepDialog() {
    this.locsToCompare = [1, 2];
    this.startDate = null;
    this.endDate = null;
    this.selectedView = null;
    this.selInsightsCrit = [];
    this.selReviewCrit = [];
    this.insightsAccordion.tabs[0].selected = false;
    this.reviewsAccordion.tabs[0].selected = false;
    this.showGenRepDialog = false;
  }

  removeChip(event, item, section) {
    event.stopPropagation();
    let tempArray = [];

    if (section == 'insights') {
      tempArray = [...this.selInsightsCrit];
      this.spliceArray(tempArray, item);
      this.selInsightsCrit = tempArray;
    }

    if (section == 'reviews') {
      tempArray = [...this.selReviewCrit];
      this.spliceArray(tempArray, item);
      this.selReviewCrit = tempArray;
    }

    if (this.selInsightsCrit.length == 0 || this.selReviewCrit.length == 0) {
      this.checkForMissingFields();
    }
  }

  spliceArray(array, item) {
    for (const elem of array) {
      if (elem == item) {
        const i = array.indexOf(elem);
        array.splice(i, 1);
      }
    }
  }

  getChipName(chip, section) {
    let tempArray = [];
    if (section == 'insights') {
      tempArray = [...this.insightsCriteria];
    }

    if (section == 'reviews') {
      tempArray = [...this.reviewCriteria];
    }

    for (const item of tempArray) {
      if (item.code == chip) {
        return item.name.toUpperCase();
      }
    }
  }

  backToSettings() {
    this.resetGenRepDialog();
    this.showOptPopup = true;
  }

  generateReport() {
    this.generatedTimestamp = new Date().toLocaleString('en-US');
    this.dateRange = this.startDate.toLocaleDateString('en-US') + ' - ' + this.endDate.toLocaleDateString('en-US');
    this.locListLen = Object.keys(this.locationsList).length;

    // populate tables
    for (let i = 0; i < this.locListLen; i++) {
      const rowData = {};
      let insightsRow = {};
      let reviewsRow = {};
      const currentLoc = this.locationsList['loc' + (i + 1)];

      rowData['LOCATION NAME'] = currentLoc.businessName;
      rowData['STORE CODE'] = currentLoc.storeCode;
      // tslint:disable-next-line: no-string-literal
      rowData['ADDRESS'] =
        currentLoc.addressLines[0] +
        ', ' +
        currentLoc.addressLines[1] +
        ', ' +
        currentLoc.city +
        ', ' +
        currentLoc.state;
      insightsRow = { ...rowData };
      reviewsRow = { ...rowData };

      if (this.selInsightsCrit.length > 0) {
        for (const insCrit of this.selInsightsCrit) {
          // hardcoded data that needs to be connected to live call
          insightsRow[insCrit.toUpperCase()] = 200;
        }
        this.insightsTableData.push(insightsRow);
      }

      if (this.selReviewCrit.length > 0) {
        for (const revCrit of this.selReviewCrit) {
          // hardcoded data that needs to be connected to live call
          reviewsRow[revCrit.toUpperCase()] = 45;
        }
        this.reviewsTableData.push(reviewsRow);
      }
    }

    this.generateGraphs();

    this.reviewsHeaders = Object.keys(this.reviewsTableData[0]);
    this.insightsHeaders = Object.keys(this.insightsTableData[0]);

    this.showGenRepDialog = false;
    this.showProgressDialog = true;
    this.triggerTimer();
  }

  generateGraphs() {
    console.log('this.locationsList :', this.locationsList);
    console.log('this.selInsightsCrit :', this.selInsightsCrit);
    console.log('this.insightsTableData :', this.insightsTableData);
    console.log('this.selReviewCrit :', this.selReviewCrit);
    console.log('this.reviewsTableData :', this.reviewsTableData);

    const labels = [];
    const insightsData = [];
    const reviewsData = [];

    for (let i = 0; i < this.locationsList.length; i++) {
      const entry = this.insightsTableData[i];
      labels.push(
        `${entry['LOCATION NAME'].toUpperCase()} ${entry['STORE CODE'].toUpperCase()} ${entry.ADDRESS.toUpperCase()}`
      );

      const insightKeys = Object.keys(this.insightsTableData);
      for (const key of insightKeys) {
        if (key !== 'LOCATION NAME' && key !== 'STORE CODE' && key !== 'ADDRESS') {
          insightsData.push({
            label: key,
            background: this.graphColors,
          });
        }
      }
    }

    this.googleInsights = {
      labels,
      datasets: insightsData,
    };
    console.log('this.googleInsights :', this.googleInsights);

    this.reviewInsights = {
      labels,
      datasets: reviewsData,
    };
    console.log('this.reviewInsights :', this.reviewInsights);
  }

  checkForMissingFields() {
    if (
      this.startDate &&
      this.endDate &&
      this.selectedView &&
      (this.selInsightsCrit.length > 0 || this.selReviewCrit.length > 0)
    ) {
      this.missingFields = false;
    }
  }

  triggerTimer() {
    setTimeout(() => {
      this.showProgressDialog = false;
      this.reportView = true;
    }, 3000);
  }

  searchLocation(event) {
    if (event.query && event.query.length > 2) {
      console.log('query:', event.query);

      const whereClause = ['-or'];

      whereClause.push(`storeCode::text ILIKE '${event.query}'`);
      whereClause.push(`addressLines[0]::text ILIKE '${event.query}'`);
      whereClause.push(`city::text ILIKE '${event.query}'`);

      console.log('whereClause :', whereClause);

      this.dbService.Location.loadObjects({ where: whereClause }).then(
        (repl) => {
          this.locSearchResults = repl.collection;
        },
        (err) => {
          console.warn('Error loading locations:', err);
        }
      );
    }
  }

  // location search on generate report popup
  resultSelected(event, locNum) {
    event.color = this.graphColors[locNum - 1];
    this.locationsList[`loc${locNum}`] = event;
  }
  ////

  //// Report view
  backToDashboard() {
    this.reportView = false;
  }
  ////

  //// load graphs
  // loadTrends() {
  //   this.socketService.sendRequest('get-trend-metrics', {}).then(
  //     (repl: any) => {
  //       const prevYear = repl.collection[0];
  //       const prevMonth = repl.collection[1];
  //       const currMonth = repl.collection[2];
  //       let callType = '';

  //       if (this.selectedRange == 3 || this.selectedRange == 6) {
  //         callType = 'month';
  //       } else if (this.selectedRange == 4) {
  //         callType = 'quarter';
  //       }

  //       if (currMonth) {
  //         // Total Views...
  //         const currViews = currMonth.viewsMaps + currMonth.viewsSearch;
  //         const prevViews = prevMonth.viewsMaps + prevMonth.viewsSearch;
  //         const prevYearViews = prevYear.viewsMaps + prevYear.viewsSearch;
  //         this.viewsDiff = this.getPercentDiff(currViews, prevViews, callType);
  //         this.yearlyViewsDiff = this.getYearlyPercentDiff(currViews, prevYearViews, callType);

  //         // Total Cliks...
  //         const currClicks = currMonth.actionsWebsite + currMonth.actionsPhone + currMonth.actionsDrivingDirections;
  //         const prevClicks = prevMonth.actionsWebsite + prevMonth.actionsPhone + prevMonth.actionsDrivingDirections;
  //         const prevYearClicks = prevYear.actionsWebsite + prevYear.actionsPhone + prevYear.actionsDrivingDirections;
  //         this.clicksDiff = this.getPercentDiff(currClicks, prevClicks, callType);
  //         this.yearlyClicksDiff = this.getYearlyPercentDiff(currClicks, prevYearClicks, callType);

  //         // Total Impressions...
  //         const currImpressions = currMonth.viewsMapsDesktop + currMonth.viewsMapsMobile + currMonth.viewsSearchDesktop + currMonth.viewsSearchMobile;
  //         const prevImpressions = prevMonth.viewsMapsDesktop + prevMonth.viewsMapsMobile + prevMonth.viewsSearchDesktop + prevMonth.viewsSearchMobile;
  //         const prevYearImpressions = prevYear.viewsMapsDesktop + prevYear.viewsMapsMobile + prevYear.viewsSearchDesktop + prevYear.viewsSearchMobile;
  //         this.impressionsDiff = this.getPercentDiff(currImpressions, prevImpressions, callType);
  //         this.yearlyImpressionsDiff = this.getYearlyPercentDiff(currImpressions, prevYearImpressions, callType);
  //       }
  //     },
  //     (err) => {
  //       console.log('Trend metrics error:', err);
  //     }
  //   );
  // }

  loadInsightsData(range, filters?: any) {
    this.viewsDiff = '';
    this.clicksDiff = '';
    this.impressionsDiff = '';
    const { timeunit = 'month', periods = 3 } = range;

    const config = {
      header: true,
    };

    this.reviewsService
      .getExportedReport(this.accountId, this.userId, 'google', 'account', timeunit, periods, filters)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          const currData = this.convertCSVToJSONService
            .csvToJson(res, config)
            ['data'].filter((data) => Object.keys(data).length > 1);

          if (currData.length) {
            const {
              labels,
              viewsMaps,
              viewsSrch,
              actionsWeb,
              actionsPhone,
              actionsDirs,
              viewsMapsDesktop,
              viewsMapsMobile,
              viewsSearchDesktop,
              viewsSearchMobile,
              conversations,
              actionsDrivingDirections,
              actionsWebsite,
              bookings,
              foodOrders,
              foodMenuClicks,
            } = this.getGraphVals(currData);

            this.demoData = [
              {
                id: 'totalClicks',
                title: 'Total Clicks',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Website',
                      backgroundColor: '#791e6c',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsWeb,
                    },
                    {
                      type: 'bar',
                      label: 'Phone',
                      backgroundColor: '#d51ba0',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsPhone,
                    },
                    {
                      type: 'bar',
                      label: 'Directions',
                      backgroundColor: '#fa6bc1',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsDirs,
                    },
                  ],
                },
              },
              {
                id: 'totalImpressions',
                title: 'Total Impressions',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Desktop Maps',
                      backgroundColor: '#206A02',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMapsDesktop,
                    },
                    {
                      type: 'bar',
                      label: 'Mobile Maps',
                      backgroundColor: '#609c51',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMapsMobile,
                    },
                    {
                      type: 'bar',
                      label: 'Desktop Search',
                      backgroundColor: '#266317',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSearchDesktop,
                    },
                    {
                      type: 'bar',
                      label: 'Mobile Search',
                      backgroundColor: '#64f241',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSearchMobile,
                    },
                  ],
                },
              },
              {
                id: 'totalDirections',
                title: 'Directions',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Directions requests',
                      backgroundColor: '#F68A8A',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsDirs,
                    },
                  ],
                },
              },
              {
                id: 'businessConversations',
                title: 'Total Conversations',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Conversations',
                      backgroundColor: '#491d8b',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: conversations,
                    },
                  ],
                },
              },
              {
                id: 'bookings',
                title: 'Bookings',
                visibility: false,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Bookings',
                      backgroundColor: '#e8e52e',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: bookings,
                    },
                  ],
                },
              },
              {
                id: 'foodOrders',
                title: 'Food Orders',
                visibility: false,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Food Orders',
                      backgroundColor: '#e85325',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: foodOrders,
                    },
                  ],
                },
              },
              {
                id: 'foodMenuClicks',
                title: 'Food Menu Clicks',
                visibility: false,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Food Orders',
                      backgroundColor: '#e85325',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: foodMenuClicks,
                    },
                  ],
                },
              },
              {
                id: 'phoneCalls',
                title: 'Phone Calls',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Phone',
                      backgroundColor: '#d51ba0',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsPhone,
                    },
                  ],
                },
              },
              {
                id: 'websiteClicks',
                title: 'Website Clicks',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Website',
                      backgroundColor: '#791e6c',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsWeb,
                    },
                  ],
                },
              },
              {
                id: 'mobileSearchImpressions',
                title: 'Mobile Search Impressions',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Mobile Search',
                      backgroundColor: '#64f241',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSearchMobile,
                    },
                  ],
                },
              },
              {
                id: 'desktopSearchImpressions',
                title: 'Desktop Search Impressions',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Desktop Search',
                      backgroundColor: '#266317',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSearchDesktop,
                    },
                  ],
                },
              },
              {
                id: 'mobileMapsImpressions',
                title: 'Mobile Maps Impressions',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Mobile Maps',
                      backgroundColor: '#609c51',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMapsMobile,
                    },
                  ],
                },
              },
              {
                id: 'desktopMapsImpressions',
                title: 'Desktop Maps Impressions',
                visibility: true,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Desktop Maps',
                      backgroundColor: '#206A02',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMapsDesktop,
                    },
                  ],
                },
              },
              {
                id: 'combinedViews',
                title: 'Combined Views',
                visibility: false,
                graphData: {
                  labels,
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Map',
                      backgroundColor: '#003a6d',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMaps,
                    },
                    {
                      type: 'bar',
                      label: 'Search',
                      backgroundColor: '#007eff',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSrch,
                    },
                    {
                      type: 'bar',
                      label: 'Website',
                      backgroundColor: '#791e6c',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsWeb,
                    },
                    {
                      type: 'bar',
                      label: 'Phone',
                      backgroundColor: '#d51ba0',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsPhone,
                    },
                    {
                      type: 'bar',
                      label: 'Directions',
                      backgroundColor: '#fa6bc1',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsDirs,
                    },
                    {
                      type: 'bar',
                      label: 'Desktop Maps',
                      backgroundColor: '#206A02',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMapsDesktop,
                    },
                    {
                      type: 'bar',
                      label: 'Mobile Maps',
                      backgroundColor: '#609c51',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsMapsMobile,
                    },
                    {
                      type: 'bar',
                      label: 'Desktop Search',
                      backgroundColor: '#266317',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSearchDesktop,
                    },
                    {
                      type: 'bar',
                      label: 'Mobile Search',
                      backgroundColor: '#64f241',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: viewsSearchMobile,
                    },
                    {
                      type: 'bar',
                      label: 'Conversations',
                      backgroundColor: '#491d8b',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: conversations,
                    },
                    // {
                    //   type: 'bar',
                    //   label: 'Directions requests',
                    //   backgroundColor: '#F68A8A',
                    //   barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    //   maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    //   data: actionsDrivingDirections,
                    // },
                    {
                      type: 'bar',
                      label: 'Website clicks',
                      backgroundColor: '#6e44ad',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: actionsWebsite,
                    },
                    {
                      type: 'bar',
                      label: 'Bookings',
                      backgroundColor: '#e8e52e',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: bookings,
                    },
                    {
                      type: 'bar',
                      label: 'Food Orders',
                      backgroundColor: '#e85325',
                      barThickness: this.mapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                      maxBarThickness: this.mapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                      data: foodOrders,
                    },
                  ],
                },
              },
            ];

            this.checkInsightsCardsVisibility();
          }

          if (!currData.length) {
          }
        },
        (err) => {
          console.log('Monthly metrics error:', err);
          if (err.category == 'DB') {
            this.insightsErr = true;
          }
        }
      );
  }

  //// helpers
  getGraphVals(currData, prevData?) {
    const labels = [];
    const viewsMaps = [];
    const viewsSrch = [];
    const actionsWeb = [];
    const actionsPhone = [];
    const actionsDirs = [];
    const viewsMapsDesktop = [];
    const viewsMapsMobile = [];
    const viewsSearchDesktop = [];
    const viewsSearchMobile = [];
    const conversations = [];
    const actionsDrivingDirections = [];
    const actionsWebsite = [];
    const bookings = [];
    const foodOrders = [];
    const foodMenuClicks = [];

    const data = this.viewGraphReportsService.mapGggoleStringsToNumber(currData);

    const { periods } = this.selectedRange;

    for (const item of data) {
      const month = item.startDate?.split('-')[1];
      const label =
        periods === 4
          ? this.getQuarterLabel(this.getMonthName(month)) + ' ' + item.startDate?.split('-')[0]
          : this.getMonthName(month) + ' ' + item.startDate?.split('-')[0];
      labels.push(label);

      viewsMaps.push(item.viewsMap);
      viewsSrch.push(item.viewsSearch);

      actionsWeb.push(item.actionsWeb); // Ask Vinitha.
      actionsPhone.push(item.actionsPhone);
      actionsDirs.push(item.actionsDir);

      viewsMapsDesktop.push(item.viewsMapsDesktop);
      viewsMapsMobile.push(item.viewsMapsMobile);
      viewsSearchDesktop.push(item.viewsSearchDesktop);
      viewsSearchMobile.push(item.viewsSearchMobile);
      conversations.push(item?.conversations);
      actionsDrivingDirections.push(item?.actionsDrivingDirections); // Ask Vinitha
      actionsWebsite.push(item?.actions__website_clicks);
      bookings.push(item?.bookings);
      foodOrders.push(item?.foodOrders);
      foodMenuClicks.push(item?.foodMenu);
    }

    return {
      labels,
      viewsMaps,
      viewsSrch,
      actionsWeb,
      actionsPhone,
      actionsDirs,
      viewsMapsDesktop,
      viewsMapsMobile,
      viewsSearchDesktop,
      viewsSearchMobile,
      conversations,
      actionsDrivingDirections,
      actionsWebsite,
      bookings,
      foodOrders,
      foodMenuClicks,
    };
  }

  getPercentDiff(current, previous, type) {
    const diff = Math.abs(previous - current);
    const pctDiff = ((diff * 100) / current).toFixed(1);
    if (current > previous) {
      return pctDiff + '% increase from last ' + (type == 'month' ? '30 days' : 'quarter');
    } else if (previous > current) {
      return pctDiff + '% decrease from last ' + (type == 'month' ? '30 days' : 'quarter');
    } else {
      return 'No change from last ' + (type == 'month' ? '30 days' : 'quarter');
    }
  }

  getYearlyPercentDiff(current, previous, type) {
    const diff = Math.abs(previous - current);
    const pctDiff = ((diff * 100) / current).toFixed(1);
    if (current > previous) {
      return pctDiff + '% increase from same ' + type + ' of last year';
    } else if (previous > current) {
      return pctDiff + '% decrease from same ' + type + ' of last year';
    } else {
      return 'No change from same ' + type + ' of last year';
    }
  }

  getRatingDiff(current, previous) {
    const diff = Math.abs(Number(current) - Number(previous)).toFixed(1);
    if (current > previous) {
      return diff + ' increase in last 30 days';
    } else if (previous > current) {
      return diff + ' decrease in last 30 days';
    } else {
      return 'No change in last 30 days';
    }
  }

  dateRangeChanged(event) {
    const { timeunit, periods } = event.value;
    this.fullWidth = periods === 12 || periods === 24 ? true : false;
    this.loadInsightsData(event.value);
  }

  getMonthName(month: number) {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'][month - 1];
  }

  //// insights popup
  editInsights() {
    this.prevInsightPrefs = [...this.selectedInsightPrefs];
    // this.disabledField = this.getDisabledField();
    this.showEditPopup = true;
  }

  cancelEditPopup() {
    this.setInsightsCardVisibility();
    this.showEditPopup = false;
  }

  private checkInsightsCardsVisibility(): void {
    this.demoData.forEach((item) => {
      item['visibility'] = this.selectedInsightPrefs.includes(item.id) ? true : false;
      return item;
    });
  }

  private setInsightsCardVisibility(): void {
    this.demoData.forEach((card) => {
      card['visibility'] = this.selectedInsightPrefs.includes(card.id) ? true : false;
    });
  }

  savePrefs() {
    this.showEditPopup = false;
  }

  getDisabledField() {
    if (this.showReviews) {
      if (this.selectedInsightPrefs.length === 3) {
        for (const val of ['totalClicks', 'totalImpressions', 'totalDirections', 'phoneCalls', 'websiteClicks']) {
          if (!this.selectedInsightPrefs.includes(val)) {
            return val;
          }
        }
      }
    }
  }

  prefsChanged() {
    this.setInsightsCardVisibility();
  }

  public openFilters() {
    console.log('filter opened');
  }

  public primaryFilters() {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Filter by Store Code',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.appleMapsInsightsDataService.setBasicFiltersData()], // Add filter data here.....
        isAdvanceFilterActive: false,
      },
    });
    this.componentRef.onClose.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      if (res && res.length && res[0]?.value?.length) {
        let storeCode: string = '';
        const storeCodeObj = res[0];
        storeCode = storeCodeObj['value'].join(',');
        const filters: string = `storecode=${storeCode}`;
        this.loadInsightsData(this.selectedRange, filters);
      }
    });
  }

  public advanceFilters() {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Advance filters',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.allAdvanceFilters], // Add filter data here.....
        isAdvanceFilterActive: true,
      },
    });
    this.componentRef.onClose.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
    let advanceFiltersQuery: string = '';
    let operand: string = `operand=${this.appleMapsInsightsDataService.getFilterOperandType}`;
      res && res?.length && res.forEach(filter => {
        if(filter.id === 'business_names' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `businessname=${filter.value.join(',')}` : `businessname=${filter.value.join(',')}`;
        }
        if(filter.id === 'city' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `&city=${filter.value.join(',')}` : `city=${filter.value.join(',')}`;
        }
        if(filter.id === 'states' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `&state=${filter.value.join(',')}` : `state=${filter.value.join(',')}`;
        }
        if(filter.id === 'countries' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `&country=${filter.value.join(',')}` : `country=${filter.value.join(',')}`;
        }
        if(filter.id === 'tag_list' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `&tag=${filter.value.join(',')}` : `tag=${filter.value.join(',')}`;
        }
        if(filter.id === 'label_list' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `&labels=${filter.value.join(',')}` : `labels=${filter.value.join(',')}`;
        }
        if(filter.id === 'category_list' && filter.value.length) {
          advanceFiltersQuery += advanceFiltersQuery.length ? `&categories=${filter.value.join(',')}` : `${filter.id}=${filter.value.join(',')}`;
        }
      });
      const filters: string = `${operand}&${advanceFiltersQuery}`;
      this.loadInsightsData(this.selectedRange, filters);
    });
  }

  private getFiltersOperandStatus(): void {
    this.appleMapsInsightsDataService.currentFilterOperandType
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((openationType: string) => {
        this.operandType = openationType;
      });
  }

  public getMetricsDataOnFilterUpdates(): void {
    this.appleMapsInsightsDataService.currentUpdatedFilterState
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        if (res) {
          this.whereClauss = this.appleMapsInsightsDataService.buildWhereClaus(res);
          this.loadData();
        }
      });
  }

  private mapMetricsData(metricsData: any[]): any[] {
    return metricsData
      .filter((el) => el.vendorIdent === 'gmb')
      .map((el) => {
        return {
          ...el,
          avgStarRating: +el.avgStarRating,
        };
      });
  }

  /**
   * @description: Get the current account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService
      .getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((account: IAccount) => {
        this.accountId = account?._id;
      });
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userId = user?.login?._id;
      });
  }

  getQuarterLabel(month): string {
    switch (month) {
      case 'Jan':
        return 'Q1';
      case 'Apr':
        return 'Q2';
      case 'Jul':
        return 'Q3';
      case 'Oct':
        return 'Q4';
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
