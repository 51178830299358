<div class="filter_box">

  <div class="filter_type" *ngIf="displayFiltersCriteria">
    <p>Filter type <i [pTooltip]="tooltipText" class="pi-question-circle"></i></p>
    <div class="filter_actns">
      <button [class.isActive]="operandType === 'AND'" (click)="updateFilterOperation('AND')" class="filter_actns_btn">AND</button>
      <button [class.isActive]="operandType === 'OR'" (click)="updateFilterOperation('OR')" class="filter_actns_btn">OR</button>
    </div>
  </div>

  <p-accordion>
    <p-accordionTab [header]="d?.label" *ngFor="let d of data">
      <div [ngSwitch]="d.fieldType">

        <ng-container *ngSwitchCase="'text'">
          <input class="filter_input" type="text" pInputText [(ngModel)]="d.value" [placeholder]="d.placeholder" />
          <p class="tooltip_text" *ngIf="d.toolTipText">{{ d.toolTipText }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="'chipInput'">
          <p-chips class="filter_chips" (onAdd)="chipAdded($event)" [(ngModel)]="d.value" (onRemove)="chipRemoved($event)"
            [placeholder]="d.placeholder">
          </p-chips>
          <p class="tooltip_text" *ngIf="d.toolTipText">{{ d.toolTipText }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
          <div class="filter_checkbox" *ngFor="let list of d.checkboxList">
            <label>{{ list?.label }}</label>
            <p-checkbox [value]="list.label" [(ngModel)]="d.value" (onChange)="onChange(list?.label)"></p-checkbox>
          </div>
        </ng-container>

      </div>
    </p-accordionTab>
  </p-accordion>

  <div class="actions">
    <button (click)="submit()" pButton class="p-button-success actions_btn">See results</button>
    <button (click)="reset()" pButton class="p-button-secondary actions_btn">Reset filter</button>
    <button (click)="closeDialog()" pButton class="p-button-danger actions_btn">Cancel</button>
  </div>

</div>
