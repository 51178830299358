import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SessionService } from '../../../backend-adapter/session.service';
import { IAuthenticateData } from './../../../backend-adapter/session.interfaces';
import { SocketService } from '../../../../core/backend-adapter/socket.service';
import { IAccount } from '../../../../acct-comps/accounts.interfaces';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-top-right-menu-block',
  templateUrl: './top-right-menu-block.component.html',
  styleUrls: ['./top-right-menu-block.component.scss'],
})
export class TopRightMenuBlockComponent implements OnInit, OnDestroy {
  @Input() currentUser: IAuthenticateData;
  @Output() showChangePwdDialog = new EventEmitter<boolean>();
  public account: IAccount;
  private subs = new Subscription();
  currentItems: any[] = [];
  itemsPerPage = 10;
  currentPage = 1;
  booleanFromData: boolean = true;

  divVisible: boolean = false;
  menuItemsUser: MenuItem[];
  userLetter = '?';
  menuExpanded = false;
  badgeVisible: boolean = false;
  locCounts: any;
  numberUnread: number;

  private ngUnsubscribe$ = new Subject();

  @ViewChild('userMenuRef', { static: false }) private userMenuRef: any;

  constructor(
    public router: Router,
    public sessionService: SessionService,
    private socketService: SocketService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const user$ = this.sessionService.getSelectedAccount$();
    user$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((user: IAccount) => {
      if (user) {
        this.account = user;
      }
    });

    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter((event) => event instanceof NavigationEnd),
        switchMap((url: NavigationEnd) => {
          if (url.url !== '/login') {
            return from(this.socketService.sendRequest('get-notification-coll', {}));
          } else {
            return of(null);
          }
        })
      )
      .subscribe((notifications: any) => {
        if (notifications !== null) {
          this.locCounts = notifications.collection;
          this.locCounts.forEach((notification) => {
            notification.isRead = Boolean(notification.isRead);
          });
          this.loadItems();
          this.getUnreadNotificationsCount();
          this.shouldShowBadge();
        }
      });

    this.initUserMenu();
    this.getUserLetter();
  }

  public initUserMenu(): void {
    this.menuItemsUser = [
      // { label: this.currentUser, icon: 'pi pi-user'},
      // {
      //   label: 'Profile',
      //   command: () => this.router.navigate(['/account-settings']),
      // },
      // {
      //   label: 'Settings',
      //   command: () => this.router.navigate(['/account-settings']),
      // },
      { label: 'Change password', command: () => this.showChangePwdDialog.emit(true) },
      { label: 'Logout', command: (event) => this.userMenuLogout(event) },
    ];
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.divVisible = false;
    }
  }

  getNotifications(): Observable<any> {
    const notification$ = from(this.socketService.sendRequest('get-notification-coll', {}));
    return notification$;
  }

  updateMarkAsRead(notification: any) {
    from(
      this.socketService.sendRequest('save-notification', {
        item: { _id: notification.notificationId, is_read: notification.isRead },
      })
    )
      .pipe(
        switchMap((res) => {
          return of(this.socketService.sendRequest('get-notification-coll', {}))
        })
      )
      .subscribe((res) => {});
  }

  markAllAsRead(locCounts) {
    locCounts.forEach((notification) => {
      if (notification.isRead) {
        return;
      }
      notification.isRead = true;
      from(
        this.socketService.sendRequest('save-notification', {
          item: {
            _id: notification.notificationId,
            is_read: notification.isRead,
          },
        })
      )
        .pipe(
          switchMap((res) => {
            return of(this.socketService.sendRequest('get-notification-coll', {}));
          })
        )
        .subscribe((res) => {});
    });
  }

  private getUnreadNotificationsCount(): number {
    this.numberUnread = this.locCounts.filter((notification) => !notification.isRead).length;
    return this.numberUnread;
  }

  hasUnreadNotifications(): boolean {
    return this.locCounts?.some((notification) => !notification.isRead);
  }

  public userMenuLogout(event): void {
    this.sessionService.deauthenticate();
  }

  //// user menu
  getUserLetter() {
    if (this.currentUser) {
      this.userLetter = this.currentUser.login.loginId.slice(0, 1).toUpperCase();
    }
  }

  toggleUserMenu(event) {
    this.userMenuRef.toggle(event);
    this.menuExpanded = !this.menuExpanded;
  }

  toggleDiv() {
    this.divVisible = !this.divVisible;
    if (this.divVisible) {
      // this.badgeVisible = false;
    }
  }

  // tab menu navigation
  setActiveTab(item) {
    console.log(item);
    this.activeItem = item;
  }
  // tab menu
  tabItems: MenuItem[] = [
    {
      label: 'REVIEWS',
      command: (event) => {
        this.setActiveTab(event.item);
      },
    },
    {
      label: 'NEW LOCATIOINS',
      command: (event) => {
        this.setActiveTab(event.item);
      },
    }
  ];
  activeItem: MenuItem = this.tabItems[0];

  loadItems(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    const newItems = this.locCounts.slice(startIndex, endIndex);
    this.locCounts.forEach((notification) => {
      notification.isRead = Boolean(notification.isRead);
    });
    this.currentItems = [...this.currentItems, ...newItems];
    this.currentPage++;
  }

  onScroll(event: any): void {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;
    const threshold = 5;
    const atBottom = scrollTop + clientHeight >= scrollHeight - threshold;
    if (atBottom) {
      this.loadItems();
    }
  }

  shouldShowBadge(): any {
    if (this.account && this.numberUnread >= 1) {
      this.badgeVisible = true;
    }
  }

  onMoreLessClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
