<div class="google-reports-wrapper">
  <div class="dashboard" *ngIf="!reportView">
    <div class="card" [ngClass]="{
        card: true,
        insights: true,
        'half-height': showReviews,
        'full-height': !showReviews
      }" *ngIf="!showReviewsOnly">
      <div class="card-header insights">
        <h3>GOOGLE INSIGHTS</h3>
        <span class="header-right">
          <p-dropdown [options]="dateRanges" [(ngModel)]="selectedRange" optionLabel="name" optionValue="value"
            (onChange)="dateRangeChanged($event)">
          </p-dropdown>
          <p-splitButton class="advance_filters" label="Filter by" [model]="tags" (onClick)="openFilters()"
            styleClass="p-button-sm">
          </p-splitButton>
          <button pButton class="edit p-button-text" icon="fa fa-pen" (click)="editInsights()"></button>
        </span>
      </div>
      <hr />

      <div class="card_holder" [class.full_width]="fullWidth" [class.half_width]="!fullWidth">
        <ng-container *ngIf="!insightsErr; else noInsights">
          <ng-container *ngFor="let data of demoData">
            <div class="chart" *ngIf="data.visibility" [class.full_width__chart]="fullWidth"
              [class.half_width__chart]="!fullWidth">
              <div class="chart_block">
                <h2 class="chart_block_title">{{ data.title }}</h2>
                <p-chart type="bar" [data]="data.graphData" [options]="stackOptions" width="100%"
                  [height]="showReviews ? '18vh' : '22vh'">
                </p-chart>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noInsights>
          <h2>No data to display</h2>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="report-view" *ngIf="reportView">
    <header>
      <button pButton type="button" label="BACK TO ALL REPORTS" class="p-button-text" icon="pi pi-chevron-left"
        (click)="backToDashboard()"></button>
      <div class="flex">
        <div class="header-parent-title">
          <div class="header-child-title">Comparison Report | {{ reportName }}</div>

          <div class="header-child-title">.</div>
        </div>

        <div class="header-buttons">
          <button pButton type="button" label="DOWNLOAD CSV" class="p-button-outlined" *ngIf="reportView"></button>
          <button pButton type="button" label="DOWNLOAD PDF" class="p-button-primary" *ngIf="reportView"></button>
        </div>
      </div>
      <h2>GENERATED {{ generatedTimestamp }}</h2>
      <p>{{ locListLen }} LOCATIONS</p>
    </header>
    <div class="report-body">
      <div class="card" *ngIf="insightsTableData.length > 0">
        <div class="card-header">GOOGLE INSIGHTS</div>
        <hr />
        <div class="card-body">
          <p>
            Date Range
            <span>{{ dateRange }}</span>
          </p>
          <div class="graph">
            <p-chart type="bar" [data]="googleInsights" [options]="stackOptions" width="100%"></p-chart>
          </div>
          <p-table [value]="insightsTableData">
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let entry of insightsHeaders">
                  <span [class]="entry.toLowerCase()">{{ entry }}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
              <tr>
                <td *ngFor="let entry of insightsHeaders; index as i; trackBy: trackIndex">
                  <span *ngIf="i == 0" [style]="{ color: graphColors[rowIndex] }"> &#11044; </span>
                  <span [class]="entry.toLowerCase()">{{ row[entry] }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="card" *ngIf="reviewsTableData.length > 0">
        <div class="card-header">REVIEWS INSIGHTS</div>
        <hr />
        <div class="card-body">
          <p>
            Date Range
            <span>{{ dateRange }}</span>
          </p>
          <div class="graph">graph</div>
          <p-table [value]="reviewsTableData">
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let entry of reviewsHeaders">
                  <span [class]="entry.toLowerCase()">{{ entry }}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
              <tr>
                <td *ngFor="let entry of reviewsHeaders; index as i; trackBy: trackIndex">
                  <span *ngIf="i == 0" [style]="{ color: graphColors[rowIndex] }"> &#11044; </span>
                  <span [class]="entry.toLowerCase()">{{ row[entry] }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Customize Insights Selection popup -->
<p-dialog id="insights" header="Customize Insights" [(visible)]="showEditPopup" [modal]="true" [dismissableMask]="true"
  (onHide)="cancelEditPopup()">
  <div class="insights_container">

    <ng-container *ngFor="let data of popupData">
      <div id={{data.id}} class="insights_list">
        <label class="insights_list_label">{{ data.label }}</label>
        <p-checkbox name="googleInsights" value={{data.value}} [(ngModel)]="selectedInsightPrefs"
          (onChange)="prefsChanged()">
        </p-checkbox>
      </div>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div>
      <button pButton label="CANCEL" class="p-button-outlined p-button-secondary" (click)="cancelEditPopup()"></button>
      <button pButton label="SAVE" (click)="savePrefs()"></button>
    </div>
  </ng-template>
</p-dialog>

<!-- Download selection popup -->
<!-- <p-dialog id="download-opts" header="Aggregate per?" [(visible)]="showDwnldPopup" [modal]="true"
  [dismissableMask]="true" (onHide)="cancelDwnldPopup()">
  <div class="popup-container">
    <button pButton label="ACCOUNT" class="p-button-outlined p-button-secondary"
      (click)="downloadReport('account')"></button>
    <button pButton label="LOCATIONS" class="p-button-primary" (click)="downloadReport('location')"></button>
  </div>
</p-dialog> -->

<!-- Comparison report popup -->
<p-dialog #comparisonRep class="comparison-rep" [(visible)]="showOptPopup" [modal]="true" [closeOnEscape]="true"
  [dismissableMask]="true" maskStyleClass="popup-mask">
  <ng-template pTemplate="header">
    <div class="header-title">
      <div class="header-text">Generate New Report</div>
      <div class="header-text">.</div>
    </div>
  </ng-template>
  <div class="popup-container">
    <input pInputText type="text" placeholder="Name Your Report…" [(ngModel)]="reportName"
      (ngModelChange)="checkForMissingOptions()" (input)="checkForMissingOptions()" />
    <div [style]="{ color: 'red', marginLeft: '2px', fontSize: '18px' }" *ngIf="missingReportName">
      Enter a name for your report to continue.
    </div>
    <div class="selection-block">
      <div class="selection-row">
        <p class="short">What are you comparing?</p>
        <span class="group-with-label">
          <div [ngClass]="{
              'p-inputgroup': true,
              selected: aggregateBy == 'locations'
            }">
            <span class="p-inputgroup-addon">
              <p-radioButton name="aggregateBy" value="locations" [(ngModel)]="aggregateBy"
                (onClick)="checkForMissingOptions()"></p-radioButton>
            </span>
            <input pInputText type="text" [disabled]="true" placeholder="Location(s) VS Location(s)" />
          </div>
          <label>Data between different locations</label>
        </span>
        <span class="group-with-label">
          <div [ngClass]="{
              'p-inputgroup': true,
              selected: aggregateBy == 'dates'
            }">
            <span class="p-inputgroup-addon">
              <p-radioButton name="aggregateBy" value="dates" [(ngModel)]="aggregateBy"
                (onClick)="checkForMissingOptions()"></p-radioButton>
            </span>
            <input pInputText type="text" [disabled]="true" placeholder="Date Range VS Date Range" />
          </div>
          <label>Data between different time periods</label>
        </span>
      </div>
      <div class="selection-row">
        <p>Are you comparing single locations or multiple locations?</p>
        <span class="group-with-label">
          <div [ngClass]="{
              'p-inputgroup': true,
              selected: compareBy == 'single'
            }">
            <span class="p-inputgroup-addon">
              <p-radioButton name="compareBy" value="single" [(ngModel)]="compareBy"
                (onClick)="checkForMissingOptions()"></p-radioButton>
            </span>
            <input pInputText type="text" [disabled]="true" placeholder="Single Location" />
          </div>
          <label>E.g.; Store #1 VS Store #2</label>
        </span>
        <span class="group-with-label">
          <div [ngClass]="{
              'p-inputgroup': true,
              selected: compareBy == 'groups'
            }">
            <span class="p-inputgroup-addon">
              <p-radioButton name="compareBy" value="groups" [(ngModel)]="compareBy"
                (onClick)="checkForMissingOptions()"></p-radioButton>
            </span>
            <input pInputText type="text" [disabled]="true" placeholder="Location Groups" />
          </div>
          <label>E.g.; Toronto store VS Ottawa Stores</label>
        </span>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton label="NEXT" class="p-button-primary" [disabled]="missingOptions" (click)="nextStep()"></button>
  </ng-template>
</p-dialog>

<!-- New Report popup -->
<p-dialog #generateRep class="generate-rep" maskStyleClass="popup-mask" [(visible)]="showGenRepDialog" [modal]="true"
  [closeOnEscape]="true" [dismissableMask]="true" (onHide)="resetGenRepDialog()">
  <ng-template pTemplate="header">
    <div class="header-title">
      <div class="header-text">Generate New Report</div>
      <div class="header-text">.</div>
    </div>
  </ng-template>
  <div class="gen-rep-content">
    <div class="inline-group" *ngFor="let locNum of locsToCompare; let i = index">
      <label>Select Location {{ locNum }}</label>
      <span class="group-no-label">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <p-autoComplete field="businessName" placeholder="Search by store code, address, city…"
            [showEmptyMessage]="true" emptyMessage="No matches found" [suggestions]="locSearchResults"
            (completeMethod)="searchLocation($event)" (onSelect)="resultSelected($event, locNum)"></p-autoComplete>
        </span>
        <span class="button-tooltip" [pTooltip]="i == 4 ? 'You can only compare a maximum of 5 locations.' : null"
          *ngIf="i == locsToCompare.length - 1">
          <button pButton class="p-button-text" icon="pi pi-plus" [disabled]="i == 4" (click)="insertInput()"></button>
        </span>
      </span>
    </div>
    <div class="inline-group">
      <label>Select Date Range</label>
      <span class="group-no-label">
        <p-calendar [(ngModel)]="startDate" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" [showIcon]="true"
          (onSelect)="checkForMissingFields()" (input)="checkForMissingFields()"></p-calendar>
        <p-calendar [(ngModel)]="endDate" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" [showIcon]="true"
          (onSelect)="checkForMissingFields()" (input)="checkForMissingFields()"></p-calendar>
      </span>
    </div>
    <div class="inline-group">
      <label>Date View</label>
      <p-dropdown [options]="dateViews" [(ngModel)]="selectedView" optionLabel="name" optionValue="code"
        placeholder="Select.." (onChange)="checkForMissingFields()"></p-dropdown>
    </div>
    <hr />
    <p>Select Criteria</p>
    <div class="inline-group">
      <label>Google Insights</label>
      <p-accordion #insightsAccordion expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <span class="accordion-header-text" *ngIf="selInsightsCrit.length == 0"> Select... </span>
            <span class="accordion-header-with-items" *ngIf="selInsightsCrit.length !== 0">
              <span *ngFor="let item of selInsightsCrit">
                <p-chip [label]="getChipName(item, 'insights')" [removable]="true" removeIconClass="pi pi-times"
                  (onRemove)="removeChip($event, item, 'insights')"></p-chip>
              </span>
            </span>
          </ng-template>
          <ng-template pTemplate="content">
            <span class="checkbox-group" *ngFor="let item of insightsCriteria">
              <p-checkbox name="insightsCriteria" [value]="item.code" [(ngModel)]="selInsightsCrit"
                (onChange)="checkForMissingFields()"></p-checkbox>
              <label>{{ item.name }}</label>
            </span>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="inline-group">
      <label>Review Insights</label>
      <p-accordion #reviewsAccordion expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <span class="accordion-header-text" *ngIf="selReviewCrit.length == 0"> Select... </span>
            <span class="accordion-header-with-items" *ngIf="selReviewCrit.length !== 0">
              <span *ngFor="let item of selReviewCrit">
                <p-chip [label]="getChipName(item, 'reviews')" [removable]="true"
                  (onRemove)="removeChip($event, item, 'reviews')"></p-chip>
              </span>
            </span>
          </ng-template>
          <ng-template pTemplate="content">
            <span class="checkbox-group" *ngFor="let item of reviewCriteria">
              <p-checkbox name="reviewCriteria" [value]="item.code" [(ngModel)]="selReviewCrit"
                (onChange)="checkForMissingFields()"></p-checkbox>
              <label>{{ item.name }}</label>
            </span>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <span>
      <button pButton label="BACK" class="p-button-secondary" (click)="backToSettings()"></button>
      <button pButton label="GENERATE" class="p-button-primary" [disabled]="missingFields"
        (click)="generateReport()"></button>
    </span>
  </ng-template>
</p-dialog>

<!-- Working on it popup -->
<p-dialog #progressPopup class="progress-popup" maskStyleClass="popup-mask" [(visible)]="showProgressDialog"
  [modal]="true" [closeOnEscape]="true" [dismissableMask]="true" (onShow)="triggerTimer()">
  <div class="progress-popup-content">
    <p-progressSpinner></p-progressSpinner>
    <div class="header-title">
      <div class="header-text">Working on it</div>
      <div class="header-text">...</div>
    </div>
    <p>Take this moment to sit back and sip your coffee. Or tea. Or water. Or juice.</p>
  </div>
</p-dialog>
