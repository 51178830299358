import { Component, OnInit } from '@angular/core';
import { MapsInsightsConfigService } from '../shared/services/apple-maps-graph-config.service';
import { GraphConfigService } from '../shared/services/graph-config.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterButtonComponent } from '../shared/components/filter-buttons/filter-buttons.component';
import { AppleMapsInsightsDataService } from '../shared/services/apple-maps-insights-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReviewsService } from 'src/app/review-management/reviews.service';
import { ConvertCSVToJSONService } from '../shared/services/convert-csv-to-json.service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { AdvanceFiltersService } from '../shared/services/advance-filters.service';

@Component({
  selector: 'bing-insights',
  templateUrl: './bing-insights.component.html',
  styleUrls: ['./bing-insights.component.scss'],
})
export class BingInsightsComponent implements OnInit {
  public showReviews: boolean = true;
  public dateRanges = [
    { name: 'Last 3 months', value: { timeunit: 'month', periods: 3 } },
    { name: 'Quarterly comparison', value: { timeunit: 'quarter', periods: 4 } },
    { name: 'Last 6 months', value: { timeunit: 'month', periods: 6 } },
    { name: 'Last 12 months', value: { timeunit: 'month', periods: 12 } },
    { name: 'Last 24 months', value: { timeunit: 'month', periods: 24 } },
  ];
  public selectedRange = { timeunit: 'month', periods: 3 };
  public fullWidth: boolean = false;
  public insightsErr: boolean = false;
  public demoData: any[] = [];
  public stackOptions;
  public showEditPopup: boolean = false;
  public popupData: any[] = [];
  public selectedInsightPrefs: string[] = [];
  public tags: any[] = [
    {
      label: 'Store code filter',
      disabled: false,
      command: () => {
        this.primaryFilters();
      },
    },
    {
      label: 'Advanced filters',
      disabled: false,
      command: () => {
        this.advanceFilters();
      },
    },
  ];
  public componentRef: DynamicDialogRef | undefined;

  private allAdvanceFilters: any[] = [];
  private ngUnsubscribe$ = new Subject();
  private accountId: number;
  private userId: number;

  constructor(
    private graphConfigService: GraphConfigService,
    private mapsInsightsConfigService: MapsInsightsConfigService,
    private dialogService: DialogService,
    private mapsInsightsDataService: AppleMapsInsightsDataService,
    private reviewsService: ReviewsService,
    private convertCSVToJSONService: ConvertCSVToJSONService,
    private sessionService: SessionService,
    private appleMapsInsightsConfigService: MapsInsightsConfigService,
    private advanceFiltersService: AdvanceFiltersService,
    private appleMapsInsightsDataService: AppleMapsInsightsDataService
  ) {}

  ngOnInit(): void {
    this.getCurrentAccountDetails();
    this.getCurrentUserDetails();

    this.stackOptions = this.mapsInsightsConfigService.stackOptions;
    this.popupData = this.graphConfigService.bingPopupData;
    this.selectedInsightPrefs = ['impressions', 'clicks', 'clicksWebsite', 'clicksDirections', 'clicksPhone'];
    this.getAdvanceFilters();

    this.loadInsightsData({ timeunit: 'month', periods: 3 });
  }

  public dateRangeChanged(event): void {
    const { timeunit, periods } = event.value;
    this.fullWidth = periods === 12 || periods === 24 ? true : false;
    this.loadInsightsData(event.value);
    // this.loadTrends();
  }

  public editInsights(): void {
    // this.prevInsightPrefs = [...this.selectedInsightPrefs];
    // this.disabledField = this.getDisabledField();
    this.showEditPopup = true;
  }

  public cancelEditPopup(): void {
    // this.setInsightsCardVisibility();
    this.showEditPopup = false;
  }

  public savePrefs(): void {
    this.showEditPopup = false;
  }

  public prefsChanged(): void {
    this.setInsightsCardVisibility();
  }

  private setInsightsCardVisibility(): void {
    this.demoData.forEach((card) => {
      card['visibility'] = this.selectedInsightPrefs.includes(card.id) ? true : false;
    });
  }

  public primaryFilters(): void {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Filter by Store Code',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.mapsInsightsDataService.setBasicFiltersData()], // Add filter data here.....
        isAdvanceFilterActive: false,
      },
    });
    this.componentRef.onClose.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      if (res && res.length && res[0]?.value?.length) {
        let storeCode: string = '';
        const storeCodeObj = res[0];
        storeCode = storeCodeObj['value'].join(',');
        const filters: string = `storecode=${storeCode}`;
        this.loadInsightsData(this.selectedRange, filters);
      }
    });
  }

  public advanceFilters(): void {
    this.componentRef = this.dialogService.open(FilterButtonComponent, {
      header: 'Advance filters',
      height: 'fit-content',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        data: [...this.allAdvanceFilters], // Add filter data here.....
        isAdvanceFilterActive: true,
      },
    });
    this.componentRef.onClose.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      let advanceFiltersQuery: string = '';
      let operand: string = `operand=${this.appleMapsInsightsDataService.getFilterOperandType}`;
        res && res?.length && res.forEach(filter => {
          if(filter.id === 'business_names' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `businessname=${filter.value.join(',')}` : `businessname=${filter.value.join(',')}`;
          }
          if(filter.id === 'city' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `&city=${filter.value.join(',')}` : `city=${filter.value.join(',')}`;
          }
          if(filter.id === 'states' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `&state=${filter.value.join(',')}` : `state=${filter.value.join(',')}`;
          }
          if(filter.id === 'countries' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `&country=${filter.value.join(',')}` : `country=${filter.value.join(',')}`;
          }
          if(filter.id === 'tag_list' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `&tag=${filter.value.join(',')}` : `tag=${filter.value.join(',')}`;
          }
          if(filter.id === 'label_list' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `&labels=${filter.value.join(',')}` : `labels=${filter.value.join(',')}`;
          }
          if(filter.id === 'category_list' && filter.value.length) {
            advanceFiltersQuery += advanceFiltersQuery.length ? `&categories=${filter.value.join(',')}` : `${filter.id}=${filter.value.join(',')}`;
          }
        });
        const filters: string = `${operand}&${advanceFiltersQuery}`;
        this.loadInsightsData(this.selectedRange, filters);
      });
  }

  /**
   * @description To get lits of advances filters based on user's authorization.
   */
  private getAdvanceFilters(): void {
    this.advanceFiltersService.getAdvanceFiltersLists(this.accountId, this.userId).subscribe((res) => {
      this.allAdvanceFilters = this.appleMapsInsightsDataService.setAdvanceFiltersData().map((el) => {
        return {
          ...el,
          checkboxList:
            res[el.id] && res[el.id]?.length
              ? res[el.id].map((item) => {
                  return {
                    label: item,
                  };
                })
              : [],
        };
      });
    });
  }

  public openFilters(): void {
    console.log('filter opened');
  }

  loadInsightsData(range, filters?: any): void {
    const { timeunit = 'month', periods = 3 } = range;

    const config = {
      header: true,
    };

    this.reviewsService
      .getExportedReport(this.accountId, this.userId, 'bing', 'account', timeunit, periods, filters)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          const currData = this.convertCSVToJSONService
            .csvToJson(res, config)
            ['data'].filter((data) => Object.keys(data).length > 1);

          console.log(currData);

          const {
            labels,
            desktopImpressions,
            mobileImpressions,
            desktopClicks,
            mobileClicks,
            desktopClicksWebsite,
            mobileClicksWebsite,
            desktopClicksDirections,
            mobileClicksDirections,
            desktopClicksPhone,
            mobileClicksPhone,
            desktopClicksPhoto,
            mobileClicksPhoto,
            desktopClicksLocatedAt,
            mobileClicksLocatedAt,
            desktopClicksReview,
            mobileClicksReview,
            desktopClicksSuggestAnEdit,
            mobileClicksSuggestAnEdit,
            desktopClicksOrderOnline,
            mobileClicksOrderOnline,
            desktopClicksMenu,
            mobileClicksMenu,
          } = this.getGraphVals(currData);

          this.demoData = [
            {
              id: 'impressions',
              title: 'Impressions',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop Imressions',
                    backgroundColor: '#791e6c',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopImpressions],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile Impressions',
                    backgroundColor: '#fa6bc1',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileImpressions],
                  },
                ],
              },
            },
            {
              id: 'clicks',
              title: 'Clicks',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop Clicks',
                    backgroundColor: '#491d8b',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicks],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile Clicks',
                    backgroundColor: '#491d00',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicks],
                  },
                ],
              },
            },
            {
              id: 'clicksWebsite',
              title: 'Clicks: Website',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop clicks website',
                    backgroundColor: '#F68A8A',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksWebsite],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile clicks website',
                    backgroundColor: '#F68A8A',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksWebsite],
                  },
                ],
              },
            },
            {
              id: 'clicksDirections',
              title: 'Clicks: Directions',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop clicks directions',
                    backgroundColor: '#e8e52e',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksDirections],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile clicks directions',
                    backgroundColor: '#e8e52e',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksDirections],
                  },
                ],
              },
            },
            {
              id: 'clicksPhone',
              title: 'Clicks: Phone',
              visibility: true,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop clicks phone',
                    backgroundColor: '#6e44ad',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksPhone],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile clicks phone',
                    backgroundColor: '#6e44ad',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksPhone],
                  },
                ],
              },
            },
            {
              id: 'clicksPhotos',
              title: 'Clicks: Photos',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Clicks photos desktop',
                    backgroundColor: '#8a3ffc',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksPhoto],
                  },
                  {
                    type: 'bar',
                    label: 'Clicks photos mobile',
                    backgroundColor: '#8a3ffc',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksPhoto],
                  },
                ],
              },
            },
            {
              id: 'clicksLocatedAt',
              title: 'Clicks: Located at',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Show case',
                    backgroundColor: '#651e7d',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksLocatedAt],
                  },
                  {
                    type: 'bar',
                    label: 'Show case',
                    backgroundColor: '#651e7d',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksLocatedAt],
                  },
                ],
              },
            },
            {
              id: 'clicksReview',
              title: 'Clicks: Review',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Clicks review desktop',
                    backgroundColor: '#cf44be',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksReview],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile clicks review',
                    backgroundColor: '#cf44be',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksReview],
                  },
                ],
              },
            },
            {
              id: 'clicksSuggestAnEdit',
              title: 'Clicks Suggest an Edit',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop clicks suggest an edit',
                    backgroundColor: '#d1548c',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksSuggestAnEdit],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile clicks suggest an edit',
                    backgroundColor: '#d1548c',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksSuggestAnEdit],
                  },
                ],
              },
            },
            {
              id: 'clicksOrderOnline',
              title: 'Clicks Order Online',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Desktop clicks order online',
                    backgroundColor: '#206A02',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksOrderOnline],
                  },
                  {
                    type: 'bar',
                    label: 'Mobile clicks order online',
                    backgroundColor: '#609c51',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksOrderOnline],
                  },
                ],
              },
            },
            {
              id: 'clicksMenu',
              title: 'Clicks Menu',
              visibility: false,
              graphData: {
                labels,
                datasets: [
                  {
                    type: 'bar',
                    label: 'Clicks menu desktop',
                    backgroundColor: '#abdadb',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...desktopClicksMenu],
                  },
                  {
                    type: 'bar',
                    label: 'Clicks menu mobile',
                    backgroundColor: '#4b797a',
                    barThickness: this.appleMapsInsightsConfigService.setBarThickness(periods, this.showReviews),
                    maxBarThickness: this.appleMapsInsightsConfigService.setMaxBarThickness(periods, this.showReviews),
                    data: [...mobileClicksMenu],
                  },
                ],
              },
            },
          ];

          this.checkInsightsCardsVisibility();
        },
        (err) => {}
      );
  }

  /**
   * @description: Get the current account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService
      .getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((account: IAccount) => {
        this.accountId = account?._id;
      });
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userId = user?.login?._id;
      });
  }

  private checkInsightsCardsVisibility(): void {
    this.demoData.forEach((item) => {
      item['visibility'] = this.selectedInsightPrefs.includes(item.id) ? true : false;
      return item;
    });
  }

  getGraphVals(currData, prevData?) {
    const labels = [];
    const desktopImpressions = [];
    const mobileImpressions = [];
    const desktopClicks = [];
    const mobileClicks = [];
    const desktopClicksWebsite = [];
    const mobileClicksWebsite = [];
    const desktopClicksDirections = [];
    const mobileClicksDirections = [];
    const desktopClicksPhone = [];
    const mobileClicksPhone = [];
    const desktopClicksPhoto = [];
    const mobileClicksPhoto = [];
    const desktopClicksLocatedAt = [];
    const mobileClicksLocatedAt = [];
    const desktopClicksReview = [];
    const mobileClicksReview = [];
    const desktopClicksSuggestAnEdit = [];
    const mobileClicksSuggestAnEdit = [];
    const desktopClicksOrderOnline = [];
    const mobileClicksOrderOnline = [];
    const desktopClicksMenu = [];
    const mobileClicksMenu = [];

    const { periods } = this.selectedRange;

    for (const item of currData) {
      const month = item.period_start_date?.split('-')[1];
      const label =
        periods === 4
          ? this.getQuarterLabel(this.getMonthName(month)) + ' ' + item.period_start_date?.split('-')[0]
          : this.getMonthName(month) + ' ' + item.period_start_date?.split('-')[0];
      labels.push(label);

      // Impressions cards data...
      const desktopImpressionsData =
        (Number.isNaN(+item?.maps_desktop_impressions) ? 0 : +item?.maps_desktop_impressions) +
        (Number.isNaN(+item?.serp_desktop_impressions) ? 0 : +item?.serp_desktop_impressions);
      const mobileImpressionsData =
        (Number.isNaN(+item?.maps_desktop_impressions) ? 0 : +item?.maps_mobile_impressions) +
        (Number.isNaN(+item?.serp_mobile_impressions) ? 0 : +item?.serp_mobile_impressions);
      desktopImpressions.push(desktopImpressionsData);
      mobileImpressions.push(mobileImpressionsData);

      // Clicks cards data...
      const desktopClicksData =
        (Number.isNaN(+item?.maps_desktop_clicks) ? 0 : +item?.maps_desktop_clicks) +
        (Number.isNaN(+item?.serp_desktop_clicks) ? 0 : +item?.serp_desktop_clicks);
      const mobileClicksData =
        (Number.isNaN(+item?.maps_mobile_clicks) ? 0 : +item?.maps_mobile_clicks) +
        (Number.isNaN(+item?.serp_mobile_clicks) ? 0 : +item?.serp_mobile_clicks);
      desktopClicks.push(desktopClicksData);
      mobileClicks.push(mobileClicksData);

      // Clicks Website cards data...
      const desktopClicksWebsiteData =
        (Number.isNaN(+item?.maps_desktop_clicks_website) ? 0 : +item?.maps_desktop_clicks_website) +
        (Number.isNaN(+item?.serp_desktop_clicks_website) ? 0 : +item?.serp_desktop_clicks_website);
      const mobileClicksWebsiteData =
        (Number.isNaN(+item?.maps_mobile_clicks_website) ? 0 : +item?.maps_mobile_clicks_website) +
        (Number.isNaN(+item?.serp_mobile_clicks_website) ? 0 : +item?.serp_mobile_clicks_website);
      desktopClicksWebsite.push(desktopClicksWebsiteData);
      mobileClicksWebsite.push(mobileClicksWebsiteData);

      // Clicks directions cards data...
      const desktopClicksDirectionsData =
        (Number.isNaN(+item?.maps_mobile_clicks_direction) ? 0 : +item?.maps_mobile_clicks_direction) +
        (Number.isNaN(+item?.serp_desktop_clicks_direction) ? 0 : +item?.serp_desktop_clicks_direction);
      const mobileClicksDirectionsData =
        (Number.isNaN(+item?.maps_mobile_clicks_direction) ? 0 : +item?.maps_mobile_clicks_direction) +
        (Number.isNaN(+item?.serp_mobile_clicks_direction) ? 0 : +item?.serp_mobile_clicks_direction);
      desktopClicksDirections.push(desktopClicksDirectionsData);
      mobileClicksDirections.push(mobileClicksDirectionsData);

      // Clicks phone cards data...
      const desktopClicksPhoneData =
        (Number.isNaN(+item?.maps_desktop_clicks_phone) ? 0 : +item?.maps_desktop_clicks_phone) +
        (Number.isNaN(+item?.serp_desktop_clicks_phone) ? 0 : +item?.serp_desktop_clicks_phone);
      const mobileClicksPhoneData =
        (Number.isNaN(+item?.maps_mobile_clicks_phone) ? 0 : +item?.maps_mobile_clicks_phone) +
        (Number.isNaN(+item?.serp_mobile_clicks_phone) ? 0 : +item?.serp_mobile_clicks_phone);
      desktopClicksPhone.push(desktopClicksPhoneData);
      mobileClicksPhone.push(mobileClicksPhoneData);

      // Clicks Photos cards data...
      const desktopClicksPhotoData =
        (Number.isNaN(+item?.maps_desktop_clicks_photos) ? 0 : +item?.maps_desktop_clicks_photos) +
        (Number.isNaN(+item?.serp_desktop_clicks_photos) ? 0 : +item?.serp_desktop_clicks_photos);
      const mobileClicksPhotoData =
        (Number.isNaN(+item?.maps_mobile_clicks_photos) ? 0 : +item?.maps_mobile_clicks_photos) +
        (Number.isNaN(+item?.serp_mobile_clicks_photos) ? 0 : +item?.serp_mobile_clicks_photos);
      desktopClicksPhoto.push(desktopClicksPhotoData);
      mobileClicksPhoto.push(mobileClicksPhotoData);

      // Clicks Located at cards data...
      const desktopClicksLocatedAtData =
        (Number.isNaN(+item?.maps_desktop_clicks_locatedat) ? 0 : +item?.maps_desktop_clicks_locatedat) +
        (Number.isNaN(+item?.serp_desktop_clicks_locatedat) ? 0 : +item?.serp_desktop_clicks_locatedat);
      const mobileClicksLocatedAtData =
        (Number.isNaN(+item?.maps_mobile_clicks_locatedat) ? 0 : +item?.maps_mobile_clicks_locatedat) +
        (Number.isNaN(+item?.serp_mobile_clicks_locatedat) ? 0 : +item?.serp_mobile_clicks_locatedat);
      desktopClicksLocatedAt.push(desktopClicksLocatedAtData);
      mobileClicksLocatedAt.push(mobileClicksLocatedAtData);

      // Clicks Review cards data...
      const desktopClicksReviewsData =
        (Number.isNaN(+item?.maps_desktop_clicks_review) ? 0 : +item?.maps_desktop_clicks_review) +
        (Number.isNaN(+item?.serp_desktop_clicks_review) ? 0 : +item?.serp_desktop_clicks_review);
      const mobileClicksReviewsData =
        (Number.isNaN(+item?.maps_mobile_clicks_review) ? 0 : +item?.maps_mobile_clicks_review) +
        (Number.isNaN(+item?.serp_mobile_clicks_review) ? 0 : +item?.serp_mobile_clicks_review);
      desktopClicksReview.push(desktopClicksReviewsData);
      mobileClicksReview.push(mobileClicksReviewsData);

      // Clicks Suggest an Edit cards data...
      const desktopClicksSuggestAnEditData =
        (Number.isNaN(+item?.maps_desktop_clicks_suggestanedit) ? 0 : +item?.maps_desktop_clicks_suggestanedit) +
        (Number.isNaN(+item?.serp_desktop_clicks_suggestanedit) ? 0 : +item?.serp_desktop_clicks_suggestanedit);
      const mobileClicksSuggestAnEditData =
        (Number.isNaN(+item?.maps_mobile_clicks_suggestanedit) ? 0 : +item?.maps_mobile_clicks_suggestanedit) +
        (Number.isNaN(+item?.serp_mobile_clicks_suggestanedit) ? 0 : +item?.serp_mobile_clicks_suggestanedit);
      desktopClicksSuggestAnEdit.push(desktopClicksSuggestAnEditData);
      mobileClicksSuggestAnEdit.push(mobileClicksSuggestAnEditData);

      // Clicks order online cards data
      const desktopClicksOrderOnlineData =
        (Number.isNaN(+item?.maps_desktop_clicks_suggestanedit) ? 0 : +item?.maps_desktop_clicks_suggestanedit) +
        (Number.isNaN(+item?.serp_desktop_clicks_suggestanedit) ? 0 : +item?.serp_desktop_clicks_suggestanedit);
      const mobileClicksOrderOnlineData =
        (Number.isNaN(+item?.maps_mobile_clicks_suggestanedit) ? 0 : +item?.maps_mobile_clicks_suggestanedit) +
        (Number.isNaN(+item?.serp_mobile_clicks_suggestanedit) ? 0 : +item?.serp_mobile_clicks_suggestanedit);
      desktopClicksOrderOnline.push(desktopClicksOrderOnlineData);
      mobileClicksOrderOnline.push(mobileClicksOrderOnlineData);

      // Clicks Menu cards data
      const desktopClicksMenuData =
        (Number.isNaN(+item?.maps_desktop_clicks_suggestanedit) ? 0 : +item?.maps_desktop_clicks_suggestanedit) +
        (Number.isNaN(+item?.serp_desktop_clicks_suggestanedit) ? 0 : +item?.serp_desktop_clicks_suggestanedit);
      const mobileClicksMenuData =
        (Number.isNaN(+item?.maps_mobile_clicks_suggestanedit) ? 0 : +item?.maps_mobile_clicks_suggestanedit) +
        (Number.isNaN(+item?.serp_mobile_clicks_suggestanedit) ? 0 : +item?.serp_mobile_clicks_suggestanedit);
      desktopClicksMenu.push(desktopClicksMenuData);
      mobileClicksMenu.push(mobileClicksMenuData);
    }

    return {
      labels,
      desktopImpressions,
      mobileImpressions,
      desktopClicks,
      mobileClicks,
      desktopClicksWebsite,
      mobileClicksWebsite,
      desktopClicksDirections,
      mobileClicksDirections,
      desktopClicksPhone,
      mobileClicksPhone,
      desktopClicksPhoto,
      mobileClicksPhoto,
      desktopClicksLocatedAt,
      mobileClicksLocatedAt,
      desktopClicksReview,
      mobileClicksReview,
      desktopClicksSuggestAnEdit,
      mobileClicksSuggestAnEdit,
      desktopClicksOrderOnline,
      mobileClicksOrderOnline,
      desktopClicksMenu,
      mobileClicksMenu,
    };
  }

  getMonthName(month: number) {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'][month - 1];
  }

  private getQuarterLabel(month): string {
    switch (month) {
      case 'Jan':
        return 'Q1';
      case 'Apr':
        return 'Q2';
      case 'Jul':
        return 'Q3';
      case 'Oct':
        return 'Q4';
    }
  }

  /**
   * NOTES
   * Create a service that will keep/ track instance of all dynamic filters and will be shared
   * amongst all directories in Insights page. Eg: Google, Yelp, Bing, Apple Maps etc.
   */
}
