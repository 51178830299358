import { Component, HostBinding, Input } from '@angular/core';
import { ICardInsightsNumbers } from '../card-insights/card-insights.interfaces';


@Component({
  selector: 'app-location-sumary-card',
  templateUrl: './location-summary-card.component.html',
  styleUrls: ['./location-summary-card.component.scss'],
})

export class LocationSummaryCardComponent {
  @Input() headerTitle: string;
  @Input() numbers: ICardInsightsNumbers[];
  @Input() subNumbers: ICardInsightsNumbers[];
  // @HostBinding('class.rs-card-container') componentClass = true;
}
