import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { DBService } from 'src/app/core/backend-adapter/db.service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';
import { IFilterData, FILTER_TYPES } from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';
import { TableFilterService } from 'src/app/core/feature-modules/table-filter/table-filter.service';
import { SYSTEM_COLORS } from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { AddEditResponseComponent } from '../components/add-edit-response/add-edit-response.component';
import {
  IReviewData,
  IReviewDataResponse,
  IReviewsMetrics,
  IReviewsMetricsResponse,
  ICols,
  IFitersObject,
} from '../review-management.interfaces';
import {ReviewsService} from '../reviews.service';
import { ExportDataToFile } from '../services/export-reviews-file.service';
import { DeletePopupComponent } from '../components/delete-popup/delete-popup.component';
import { Subject, from, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { ReviewsCopyClipboardService } from '../services/reviews-copy-clipboard.service';

@Component({
  selector: 'app-yelp-reviews-tab',
  templateUrl: './yelp-reviews-tab.component.html',
  styleUrls: ['./yelp-reviews-tab.component.scss'],
})
export class YelpReviewsTabComponent implements OnInit, OnDestroy {
  SYSTEM_COLORS = SYSTEM_COLORS;
  datePosted: any[];
  public keyword = '';
  sortOrder = '';
  filterData: IFilterData[];
  chipsArray: string[] = [];
  showMultiReplyPopup = false;
  totalReviewsCount: number;
  loading = false;
  reviewData: IReviewData[];
  selectedReviews: IReviewData[] = [];
  showOverview = true;
  cols: ICols[] = [];
  offset = 0;
  pageSize = 50;
  reviewsData: IReviewsMetrics;
  private filters: IFitersObject = {};
  private yelpOauthComplete = false;
  @ViewChild('reviewTbl', {static: false}) reviewTbl: Table;

  actionItems = [
    // {
    //   label: 'Selected (none)',
    //   command: () => {
    //     this.exportReviews(this.reviewTbl);
    //   },
    //   disabled: true,
    // },
    {
      label: `This page (0 items)`,
      command: () => {
        this.exportAllReviews(this.reviewTbl);
      },
      disabled: false,
      id: 'selectAll'
    },
    {
      label: 'All items (up to 10,000)',
      command: () => {
        this.exportFilteredReviews();
      }
    }
  ];
  public enableSelectAllReviews: boolean = false;
  public selectAllReviews: boolean = false;
  public search: string = '';
  public dailyAvgRatings: any;
  public dailyTotalReviews: any;
  public startDate: string;
  public endDate: string;

  private fileName: string = 'ExcelSheet.xlsx';
  private ngUnsubscribe$: Subject<any> = new Subject();
  private selectedDateRange: string = 'DefaultDate';
  private dateRangeSet = new Set(['This month', 'Last 30 days', 'Last month', 'Year to date', 'Last 12 months', 'All time']);
  private accountId: number;
  private loginId: number;

  constructor(
    public dbService: DBService,
    public socketService: SocketService,
    private notifyService: NotifyService,
    private tableFilterService: TableFilterService,
    private dialogService: DialogService,
    public sessionService: SessionService,
    private reviewsService: ReviewsService,
    private exportDataToFile: ExportDataToFile,
    private reviewsCopyClipboardService: ReviewsCopyClipboardService
  ) {}

  ngOnInit(): void {
    this.getCurrentAccount();
    this.getLoginId();
    this.sessionService.getCurrentUser$().subscribe((user) => {
        this.yelpOauthComplete = user.oauthVendors?.indexOf('yelp') > -1;
    });

    this.loadReviewsData();
    this.loadReviews();

    this.filterData = [
      {
        type: FILTER_TYPES.MULTI,
        title: 'REVIEWS',
        options: ['Responded to', 'Not yet responded to'],
      },
      {
        type: FILTER_TYPES.MULTI,
        title: 'RATINGS',
        options: ['5', '4', '3', '2', '1'],
      },
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: ['This month', 'Last 30 days', 'Last month', 'Year to date', 'Last 12 months', 'All time'],
      },
      {
        type: FILTER_TYPES.SINGLE,
        title: 'REVIEW TEXT',
        options: ['Review includes text', 'Review does not include text'],
      },
    ];

    this.cols = [
      {field: 'storeCode', header: 'STORE CODE'},
      {field: 'businessName', header: 'LOCATION NAME'},
      {field: 'addressLines', header: 'STREET ADDRESS'},
      {field: 'city', header: 'CITY'},
      {field: 'state', header: 'STATE'},
      {field: 'postalCode', header: 'POSTAL CODE'},
      {field: 'tags', header: 'TAGS'},
      {field: 'reviewCreatedAt', header: 'REVIEW DATE'},
      {field: 'reviewerComment', header: 'REVIEW'},
      {field: 'reviewerName', header: 'REVIEWER NAME'},
      {field: 'starRating', header: 'RATING'},
      {field: 'replyUpdatedAt', header: 'RESPONSE DATE'},
      {field: 'replyComment', header: 'RESPONSE'},
    ];
  }

  // dashboard
  toggleOverview() {
    this.showOverview = !this.showOverview;
  }

  hideOverview(toggle: boolean) {
    this.showOverview = !toggle;
  }

  // initial load
  loadReviewsData() {
    const request1$ = from(
      this.socketService.sendRequest('get-review-metrics', { includeAllTime: true, where: this.buildWhereClause() })
    );
    const request2$ = from(
      this.socketService.sendRequest('get-daily-review-metrics', {
        excludePast30Days: true,
        where: this.buildWhereClause(),
      })
    );

    zip(request1$, request2$)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        ([res1, res2]: [IReviewsMetricsResponse, any]) => {
          if (res1['collection'][0] || res2['collection']) {
            if (this.dateRangeSet.has(this.selectedDateRange)) {
              this.startDate = res1['collection'][0]?.dateStart;
              this.endDate = res1['collection'][0]?.dateEnd;
            }
            const dataSet1 = res1['collection'] && res1['collection']?.length && res1['collection'][0];
            this.totalReviewsCount = dataSet1['totalReviews'] || 0;
            this.dailyAvgRatings =
              res2['collection'] &&
              res2['collection'].length &&
              res2['collection'].map((el) => ({ date: el.date, value: +el.average_starrating }));
            this.dailyTotalReviews =
              res2['collection'] &&
              res2['collection'].length &&
              res2['collection'].map((el) => ({ date: el.date, value: +el.total_reviews }));
            this.reviewsData = { ...dataSet1 };
          } else {
            this.notifyService.error('There is no data for this account');
          }
        },
        (err: Error) => {
          this.notifyService.error('There is no data for this account');
        }
      );
  }

  private buildWhereClause(): string[] {
    const filterClauses: string[][] = [];
    const tempFilterArr: string[] = [];
    const isFiltersApplied = this.chipsArray.length > 0;

    const pushQueriesToMainArray = () => {
      let filterClause: Array<string> = ['-or'];
      filterClause = filterClause.concat(tempFilterArr);
      filterClauses.push(filterClause);
      tempFilterArr.length = 0;
    };

    if (this.chipsArray.length) {
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.length > 0 && value[0] != undefined) {
          switch (key) {
            case 'REVIEWS':
              value.forEach((element: string) => {
                element == 'Responded to'
                  ? tempFilterArr.push(`NOT replyComment IS NULL`)
                  : tempFilterArr.push(`replyComment IS NULL`);
              });
              pushQueriesToMainArray();
              break;
            case 'RATINGS':
              value.forEach((element: string) => {
                switch (element) {
                  case '5':
                    tempFilterArr.push(`starRating = 5`);
                    break;
                  case '4':
                    tempFilterArr.push(`starRating = 4`);
                    break;
                  case '3':
                    tempFilterArr.push(`starRating = 3`);
                    break;
                  case '2':
                    tempFilterArr.push(`starRating = 2`);
                    break;
                  case '1':
                    tempFilterArr.push(`starRating = 1`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'DATE':
              value.forEach((element: string) => {
                this.selectedDateRange = element;
                switch (element) {
                  case 'This month':
                    tempFilterArr.push(`extract(month from r.date) = extract(month from current_date) and extract(year from r.date) = extract(year from current_date)`);
                    break;
                  case 'Last 30 days':
                    tempFilterArr.push(`r.date >= (now() - interval '30 day')`);
                    break;
                  case 'Last month':
                    tempFilterArr.push(
                      `extract(month from r.date) = extract(month from current_date) - 1 and extract(year from r.date) = extract(year from current_date)`
                    );
                    break;
                  case 'Year to date':
                    tempFilterArr.push(`extract(year from r.date) = extract(year from current_date)`);
                    break;
                  case 'Last 12 months':
                    tempFilterArr.push(`r.date > (now() - interval '12 month')`);
                    break;
                  case 'All time':
                    break;
                  case 'DefaultDate':
                    this.startDate = new Date().toISOString().slice(0, 10);
                    this.endDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
                    tempFilterArr.push(`r.date > (now() - interval '30 day')`);
                    break;
                  default:
                    const dateArray: string[] = element.split(' - ');
                    this.startDate = dateArray[0];
                    this.endDate = dateArray[1];
                    tempFilterArr.push(`reviewCreatedAt >= '${dateArray[0]}' AND reviewCreatedAt <= '${dateArray[1]}'`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'REVIEW TEXT':
              value.forEach((element: string) => {
                switch (element) {
                  case 'Review includes text':
                    tempFilterArr.push(`reviewercomment IS NOT NULL`);
                    break;
                  case 'Review does not include text':
                    tempFilterArr.push(`reviewercomment IS NULL`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
          }
        }
      }
    }    else {
      this.selectedDateRange = 'DefaultDate';
      this.startDate = new Date().toISOString().slice(0, 10);
      this.endDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
      // Adding default lst 30 days option if no date filter is selected.
      tempFilterArr.push(`r.date >= (now() - interval '30 day')`);
      pushQueriesToMainArray();
      this.startDate = new Date().toISOString().slice(0, 10);
      this.endDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
    }

    if (this.keyword) {
      tempFilterArr.push(`storeCode::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`businessName::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`reviewerComment::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`starRating::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`reviewCreatedAt::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`replyComment::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`replyUpdatedAt::text ILIKE '${this.keyword}%'`);
      pushQueriesToMainArray();
    }
    let whereClauseArray: Array<any> = ['-and', "r._status IN ('A', 'UA', 'UP')", `vendorIdent='yelp-yelp'`];
    whereClauseArray = whereClauseArray.concat(filterClauses);
    return whereClauseArray;
  }

  loadReviews() {
    this.loading = true;

    if(this.loading) {
      this.selectAllReviews = false;
      if(!this.selectAllReviews) {
        this.selectedReviews = [];
      }
    }

    this.dbService.ReviewGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      order: this.sortOrder,
      where: this.buildWhereClause(),
    })
      .then(
        (repl: IReviewDataResponse) => {
          this.reviewData = repl.collection;
          this.totalReviewsCount = repl.totalEntries;
          for (const obj of this.reviewData) {
            this.datePosted = obj.reviewCreatedAt;
            if (obj.reviewerComment != null && obj.reviewerComment.length > 55) {
              obj.reviewTooltip = `<span style="font-style: italic; padding-bottom: 15px">"${obj.reviewerComment}"</span>
                           <p style="text-align: left; margin-top: 12px"><b>Posted by</b> ${obj.reviewerName}</p>
                           <p style="text-align: left; margin: 0">on <b>${obj.reviewCreatedAt.day}/${obj.reviewCreatedAt.month}/${obj.reviewCreatedAt.year} ${obj.reviewCreatedAt.hour}:${obj.reviewCreatedAt.minute}</b></p>`;
            }
          }
          this.actionItems.forEach(item => {
            if (item.id === 'selectAll') {
              item.label = `This page (${this.reviewData.length} items)`
            }
          });
        },
        (err: Error) => {
          this.reviewData = [];
          console.warn('Error loading ReviewGMB: %j', err);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  // openEditResponse(review: IReviewData) {
    // this.dialogService.open(AddEditResponseComponent, {
    //   data: { ...review },
    //   closable: false,
    //   width: '70%',
    //   height: '70%',
    // });
  // }

  openDeleteResponse(review: IReviewData) {
    // this.dialogService.open(DeletePopupComponent, {
    //   data: { ...review },
    //   closable: false,
    //   width: '40%',
    // });
  }

  addMultiReply() {
    this.showMultiReplyPopup = true;
  }

  cancelMultiReply() {
    this.showMultiReplyPopup = false;
  }

  getInfoFromDialog(toggle: boolean) {
    this.showMultiReplyPopup = toggle; // close the dialog
    this.selectedReviews = [];
    this.selectAllReviews = false;
  }

  lazyLoadLocations(event: LazyLoadEvent) {
    if (event.sortField == 'directory') {
      return;
    }
    this.offset = event.first;
    if (event.sortField) {
      this.sortOrder = `${event.sortField}${event.sortOrder < 0 ? ' DESC' : ''}`;
    }
    return this.loadReviews();
  }

  onChipsRefresh(event: IFitersObject) {
    this.chipsArray = [].concat.apply([], Object.values(event)); // merge all checkbox values into one array
    this.chipsArray = this.chipsArray.filter((e) => e); // remove all possible undefined
    if(!event.hasOwnProperty('DATE')) {
      event = { ...event, DATE: ['DefaultDate'] };
    } else if(event.hasOwnProperty('DATE') && !event['DATE'].length) {
      event = { ...event, DATE: ['DefaultDate'] };
    }
    this.filters = event;
    this.loadReviewsData();
    this.loadReviews();
  }

  openEditResponse(review: IReviewData) {
    if (!this.yelpOauthComplete) {
      let initAuthURL;
      this.socketService.sendRequest('make-oauth-request-url', { vendorIdent: 'yelp' })
        .then(repl => {
          initAuthURL = repl['url'];
          window.open(initAuthURL, '_blank', 'popup=true,innerWidth=600,innerHeight=800,top=300,left=300');
        });
    } else {
      this.dialogService.open(AddEditResponseComponent, {
          data: { review: review,
            accountId: this.accountId,
            loginId: this.loginId,
          },
          closable: false,
          width: '70%',
          height: '70%',
        }).onClose.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
          const { data = null } = res;
          if(data !== null) {
            this.reviewData = this.reviewData.map(r => {
              if(r._id === data._id) {
                return {
                  ...r,
                  replyComment: data.replyComment,
                }
              }
              return r;
            })
          }
        })
    }
  }

  public exportReviews(table: Table) {
    table._selection = this.updateTableData(table._selection);
    this.exportDataToFile.generateCSVFile(table, { selectionOnly: true });
  }

  public exportAllReviews(table: Table): void {
    this.enableSelectAllReviews = !this.enableSelectAllReviews;
    if (this.enableSelectAllReviews) {
      this.selectedReviews = this.reviewData
      table._selection = this.updateTableData(table._value);
      this.exportDataToFile.generateCSVFile(table, { selectionOnly: true });
    }
  }

  private updateTableData(selectedItems: any[] = []): any[] {
    if (selectedItems.length) {
      return selectedItems.map((item) => {
        return {
          ...item,
          reviewerComment: `Yelp Review text only available in console.`,
          tags: item?.tags && item?.tags.length && item.tags.join(', ').replace(/_/g, ' ').split(','),
        }
      });
    } else {
      return [];
    }
  }

  onRowSelect(event) {
    this.selectAllReviews = this.selectedReviews.length === this.reviewData.length;
    // if (this.selectedReviews.length > 0) {
    //   this.actionItems[1].disabled = false;
    //   this.actionItems[0].label = `Selected (${this.selectedReviews.length})`;
    // } else {
    //   this.actionItems[1].disabled = true;
    //   this.actionItems[0].label = `Selected (none)`;
    // }
  }

  public exportFilteredReviews(): void {
    const params = {
      order: this.sortOrder,
      where: this.buildWhereClause()
    };

    this.exportDataToFile.getReviews(params);
  }

  handleSelectAllReviews(event) {
    this.selectedReviews = event?.checked ? this.reviewData : []
  }

  public deleteReview(review): void {
    this.dialogService.open(DeletePopupComponent, {
      data: { ...review, actionType: 'Review', },
      closable: false,
      width: '40%',
    }).onClose.subscribe(res => {
      if(res) {
        this.notifyService.success('Success: Review deleted successfully.');
        this.loadReviews();
      } else {
        this.notifyService.error('Error: Error in deleting the Review. Please try again!');
      }
    });
  }

  public userSearch(): void {
    this.loadReviewsData();
    this.loadReviews();
  }


  ngOnDestroy(): void {
    this.tableFilterService.clearFilters(); // clear table filters
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

    /**
   * @description: Get the account details.
   */
    private getCurrentAccount(): void {
      this.sessionService
        .getSelectedAccount$()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((account: IAccount) => {
          this.accountId = account?._id;
        });
    }

    public copyReviewToClipboard(review: IReviewData): void {
      const reviewsObjectCopy = this.reviewsCopyClipboardService.mapReviewDataCopyObject(review);
      const reviewsTextCopy = this.reviewsCopyClipboardService.mapReviewsDataCopyString(reviewsObjectCopy);
      const textArea = document.createElement('textarea');
      textArea.value = reviewsTextCopy;
      navigator.clipboard.writeText(textArea.value);
      this.notifyService.success('Review copied to clipboard');
    }

    /**
     * @description: Get the current user details.
     * @returns: void
     * @arguments: void
     */
    private getLoginId(): void {
      this.sessionService
        .getCurrentUser$()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((user) => {
          this.loginId = user?.login?._id;
        });
    }

}
