<div class="yelp-reports-wrapper">
  <div class="dashboard" *ngIf="!reportView">
    <div class="card" [ngClass]="{
        card: true,
        insights: true,
        'half-height': showReviews,
        'full-height': !showReviews
      }" *ngIf="!showReviewsOnly">
      <div class="card-header insights">
        <h3>YELP INSIGHTS</h3>
        <span class="header-right">
          <p-dropdown [options]="dateRanges" [(ngModel)]="selectedRange" optionLabel="name" optionValue="value"
            (onChange)="dateRangeChanged($event)"></p-dropdown>
            <p-splitButton
            #actions
            label="Filter by"
            [model]="tags"
            (onClick)="actions.onDropdownButtonClick(null)"
            styleClass="p-button-sm">
          </p-splitButton>
          <button pButton class="edit p-button-text" icon="fa fa-pen" (click)="editInsights()"></button>
        </span>
      </div>
      <hr />

      <div class="card_holder" [class.full_width]="fullWidth" [class.half_width]="!fullWidth">
        <ng-container *ngIf="!insightsErr; else noInsights">
          <ng-container *ngFor="let data of demoData">
            <div class="chart" *ngIf="data.visibility" [class.full_width__chart]="fullWidth"
              [class.half_width__chart]="!fullWidth">
              <div class="chart_block">
                <h2 class="chart_block_title">{{ data.title }}</h2>
                <p-chart type="bar" [data]="data.graphData" [options]="stackOptions" width="100%"
                  [height]="showReviews ? '18vh' : '22vh'">
                </p-chart>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noInsights>
          <h2>No data to display</h2>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<p-dialog id="insights" header="Header" [(visible)]="showEditPopup" [modal]="true" [dismissableMask]="true"
  (onHide)="cancelEditPopup()">
  <ng-template pTemplate="header">
    <div class="insights_container">
      <ng-container *ngFor="let data of popupData">
        <p class="insights_title">{{ data.header }}</p>
        <ng-container *ngFor="let conf of data.config">
          <div id="{{ conf.id }}" class="insights_list">
            <label class="insights_list_label">{{ conf.label }}</label>
            <p-checkbox name="googleInsights" value="{{ conf.value }}" [(ngModel)]="selectedInsightPrefs"
              (onChange)="prefsChanged()">
            </p-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div>
      <button pButton label="CANCEL" class="p-button-outlined p-button-secondary" (click)="cancelEditPopup()"></button>
      <button pButton label="SAVE" (click)="savePrefs()"></button>
    </div>
  </ng-template>
</p-dialog>
