import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ViewGraphReportsService {
  private readonly graphConfigs = {
    type: 'bar',
    barThickness: 30,
    maxBarThickness: 60,
  };

  public mapGggoleStringsToNumber(data) {
    return data.map((el) => {
      return {
        viewsMap: Number.isNaN(+el?.views__maps) ? 0 : +el?.views__maps,
        viewsSearch: Number.isNaN(+el?.views__search) ? 0 : +el?.views__search,
        actionsPhone: Number.isNaN(+el?.actions__phone) ? 0 : +el?.actions__phone,
        actionsDir: Number.isNaN(+el?.actions__directions) ? 0 : +el?.actions__directions,
        viewsMapsDesktop: Number.isNaN(+el?.views__maps__desktop) ? 0 : +el?.views__maps__desktop,
        viewsMapsMobile: Number.isNaN(+el?.views__maps__mobile) ? 0 : +el?.views__maps__mobile,
        viewsSearchDesktop: Number.isNaN(+el?.views__search__desktop) ? 0 : +el?.views__search__desktop,
        viewsSearchMobile: Number.isNaN(+el?.views__search__mobile) ? 0 : +el?.views__search__mobile,
        conversations: Number.isNaN(+el?.conversations) ? 0 : +el?.conversations,
        bookings: Number.isNaN(+el?.bookings) ? 0 : +el?.bookings,
        foodOrders: Number.isNaN(+el?.foodOrders) ? 0 : +el?.foodOrders,
        foodMenu: Number.isNaN(+el.actions__food_menu) ? 0 : +el?.actions__food_menu,
        actionsWeb: Number.isNaN(+el.actions__website_clicks) ? 0 : +el?.actions__website_clicks,
        startDate: el.period_start_date,
      };
    });
  }

  private removeDuplicates(data) {
    const mapedData = new Map();
    const mapedArray = [];
    data.forEach((d) => {
      !mapedData.has(d.startDate)
        ? mapedData.set(d.startDate, d)
        : mapedData.set(d.startDate, {
            ...mapedData.get(d.startDate),
            viewsMap: mapedData.get(d.startDate).viewsMap + d.viewsMap,
            viewsSearch: mapedData.get(d.startDate).viewsSearch + d.viewsSearch,
            actionsPhone: mapedData.get(d.startDate).actionsPhone + d.actionsPhone,
            actionsDir: mapedData.get(d.startDate).actionsDir + d.actionsDir,
            viewsMapsDesktop: mapedData.get(d.startDate).viewsMapsDesktop + d.viewsMapsDesktop,
            viewsMapsMobile: mapedData.get(d.startDate).viewsMapsMobile + d.viewsMapsMobile,
            viewsSearchDesktop: mapedData.get(d.startDate).viewsSearchDesktop + d.viewsSearchDesktop,
            viewsSearchMobile: mapedData.get(d.startDate).viewsSearchMobile + d.viewsSearchMobile,
            conversations: mapedData.get(d.startDate).conversations + d.conversations,
            bookings: mapedData.get(d.startDate).bookings + d.bookings,
            foodOrders: mapedData.get(d.startDate).foodOrders + d.foodOrders,
            foodMenu: mapedData.get(d.startDate).foodMenu + d.foodMenu,
            actionsWeb: mapedData.get(d.startDate).actionsWeb + d.actionsWeb,
          });
    });

    for (const [key, value] of mapedData) {
      if (key) {
        mapedArray.push(value);
      }
    }
    return mapedArray;
  }

  public mapGoogleInsightsData(graphData, dateLabels: string[], timePeriod: any) {
    const { periods } = timePeriod['value'];

    let viewsMap: number[] = [];
    let viewsSearch: number[] = [];
    let actionsPhone: number[] = [];
    let actionsDir: number[] = [];
    let viewsMapsDesktop: number[] = [];
    let viewsMapsMobile: number[] = [];
    let viewsSearchDesktop: number[] = [];
    let viewsSearchMobile: number[] = [];
    let conversations: number[] = [];
    let bookings: number[] = [];
    let foodOrders: number[] = [];
    let foodMenu: number[] = [];
    let actionsWeb: number[] = [];
    let labels: string[] = [];

    let isGraphSingleBar: boolean;
    isGraphSingleBar =
      timePeriod === 'day_previous' ||
      timePeriod === 'week_current' ||
      timePeriod === 'week_previous' ||
      timePeriod === 'month_current' ||
      timePeriod === 'month_previous'
        ? true
        : false;
    const newArr = isGraphSingleBar
      ? this.mapGggoleStringsToNumber(graphData)
      : this.removeDuplicates(this.mapGggoleStringsToNumber(graphData));

    newArr.forEach((el) => {
      viewsMap.push(el.viewsMap);
      viewsSearch.push(el.viewsSearch);
      actionsPhone.push(el.actionsPhone);
      actionsDir.push(el.actionsDir);
      viewsMapsDesktop.push(el.viewsMapsDesktop);
      viewsMapsMobile.push(el.viewsMapsMobile);
      viewsSearchDesktop.push(el.viewsSearchDesktop);
      viewsSearchMobile.push(el.viewsSearchMobile);
      conversations.push(el.conversations);
      bookings.push(el.bookings);
      foodOrders.push(el.foodOrders);
      foodMenu.push(el.foodMenu);
      actionsWeb.push(el.actionsWeb);
      if (periods === 4 || periods === 8) {
        const month = el.startDate?.split('-')[1];
        const label = this.getQuarterLabel(this.getMonthName(month)) + ' ' + el.startDate?.split('-')[0];
        labels.push(label);
      } else {
        labels.push(el.startDate);
      }
    });

    return [
      {
        id: 'searchCard',
        title: 'Search Clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Website',
              backgroundColor: '#791e6c',
              data: actionsWeb,
              ...this.graphConfigs,
            },
            {
              ...this.graphConfigs,
              label: 'Phone',
              backgroundColor: '#d51ba0',
              data: actionsPhone,
            },
            {
              ...this.graphConfigs,
              label: 'Directions',
              backgroundColor: '#fa6bc1',
              data: actionsDir,
            },
          ],
        },
      },
      {
        id: 'viewsSearchViewsMaps',
        title: 'Views',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Map',
              backgroundColor: '#003a6d',
              data: viewsMap,
              ...this.graphConfigs,
            },
            {
              label: 'Search',
              backgroundColor: '#007eff',
              data: viewsSearch,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'totalImpressions',
        title: 'Total Impressions',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Desktop Maps',
              backgroundColor: '#206A02',
              data: viewsMapsDesktop,
              ...this.graphConfigs,
            },
            {
              label: 'Mobile Maps',
              backgroundColor: '#609c51',
              data: viewsMapsMobile,
              ...this.graphConfigs,
            },
            {
              label: 'Desktop Search',
              backgroundColor: '#266317',
              data: viewsSearchDesktop,
              ...this.graphConfigs,
            },
            {
              label: 'Mobile Search',
              backgroundColor: '#64f241',
              data: viewsSearchMobile,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'businessConversations',
        title: 'Business Conversations',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Conversations',
              backgroundColor: '#491d8b',
              data: conversations,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'bookings',
        title: 'Bookings',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Bookings',
              backgroundColor: '#e8e52e',
              data: bookings,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'foodOrders',
        title: 'Food Orders',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Food Orders',
              backgroundColor: '#e85325',
              data: foodOrders,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'foodMenuClicks',
        title: 'Food Menu Clicks',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Food Orders',
              backgroundColor: '#e85325',
              data: foodMenu,
              ...this.graphConfigs,
            },
          ],
        },
      },
    ];
  }

  private mapAppleStringsToNumber(data) {
    return data.map((el) => {
      return {
        startDate: el.period_start_date,
        pcjoinwaitlist: Number.isNaN(+el?.placecard_actions__join_waitlist) ? 0 : +el?.placecard_actions__join_waitlist,
        pcorderdelivery: Number.isNaN(+el?.placecard_actions__delivery_orders)
          ? 0
          : +el?.placecard_actions__delivery_orders,
        pcorderfood: Number.isNaN(+el?.placecard_actions__food_orders) ? 0 : +el?.placecard_actions__food_orders,
        pcordertakeout: Number.isNaN(+el?.placecard_actions__takeout_orders)
          ? 0
          : +el?.placecard_actions__takeout_orders,
        pcpickup: Number.isNaN(+el?.placecard_actions__pickup) ? 0 : +el?.placecard_actions__pickup,
        pcreserveparking: Number.isNaN(+el?.placecard_actions__reserve_parking) ? 0 : +el?.placecard_actions__reserve_parking,
        pcreservetable: Number.isNaN(+el?.placecard_actions__reserve_table) ? 0 : +el?.placecard_actions__reserve_table,
        pcscheduleappt: Number.isNaN(+el?.placecard_actions__appointments) ? 0 : +el?.placecard_actions__appointments,
        pctapcall: Number.isNaN(+el?.placecard_actions__calls) ? 0 : +el?.placecard_actions__calls,
        pctapdirection: Number.isNaN(+el?.placecard_actions__directions) ? 0 : +el?.placecard_actions__directions,
        pctaporder: Number.isNaN(+el?.placecard_actions__orders) ? 0 : +el?.placecard_actions__orders,
        pctapshare: Number.isNaN(+el?.placecard_actions__shares) ? 0 : +el?.placecard_actions__shares,
        pctapshowcase: Number.isNaN(+el?.placecard_actions__showcase_clicks)
          ? 0
          : +el?.placecard_actions__showcase_clicks,
          pctapwebsite: Number.isNaN(+el?.placecard_actions__website_clicks)
          ? 0
          : +el?.placecard_actions__website_clicks,
          pcview: Number.isNaN(+el?.placecard_views) ? 0 : +el?.placecard_views,
          pcviewavail: (Number.isNaN(+el?.placecard_actions__view_availability) ? 0 : (+el?.placecard_actions__view_availability)),
          pcviewmenu: (  Number.isNaN(+el?.placecard_actions__view_menu) ? 0 : (+el?.placecard_actions__view_menu)),
          schlocationtap: (  Number.isNaN(+el?.search_clicks__total) ? 0 : (+el?.search_clicks__total)),
          schlocationtapcategory: Number.isNaN(+el?.search_clicks__category_search) ? 0 : +el?.search_clicks__category_search,
          schlocationtapname: Number.isNaN(+el?.search_clicks__name_search) ? 0 : +el?.search_clicks__name_search,
          schlocationtapother: Number.isNaN(+el?.search_clicks__other_search) ? 0 : +el?.search_clicks__other_search,
      };
    });
  }

  private removeAppleDuplicates(data) {
    const mapedData = new Map();
    const mapedArray = [];
    data.forEach((d) => {
      !mapedData.has(d.startDate)
        ? mapedData.set(d.startDate, d)
        : mapedData.set(d.startDate, {
            ...mapedData.get(d.startDate),
            pcjoinwaitlist: mapedData.get(d.startDate).pcjoinwaitlist + d.pcjoinwaitlist,
            pcorderdelivery: mapedData.get(d.startDate).pcorderdelivery + d.pcorderdelivery,
            pcorderfood: mapedData.get(d.startDate).pcorderfood + d.pcorderfood,
            pcordertakeout: mapedData.get(d.startDate).pcordertakeout + d.pcordertakeout,
            pcpickup: mapedData.get(d.startDate).pcpickup + d.pcpickup,
            pcreserveparking: mapedData.get(d.startDate).pcreserveparking + d.pcreserveparking,
            pcreservetable: mapedData.get(d.startDate).pcreservetable + d.pcreservetable,
            pcscheduleappt: mapedData.get(d.startDate).pcscheduleappt + d.pcscheduleappt,
            pctapcall: mapedData.get(d.startDate).pctapcall + d.pctapcall,
            pctapdirection: mapedData.get(d.startDate).pctapdirection + d.pctapdirection,
            pctaporder: mapedData.get(d.startDate).pctaporder + d.pctaporder,
            pctapshare: mapedData.get(d.startDate).pctapshare + d.pctapshare,
            pctapshowcase: mapedData.get(d.startDate).pctapshowcase + d.pctapshowcase,
            pctapwebsite: mapedData.get(d.startDate).pctapwebsite + d.pctapwebsite,
            pcview: mapedData.get(d.startDate).pcview + d.pcview,
            pcviewavail: mapedData.get(d.startDate).pcviewavail + d.pcviewavail,
            pcviewmenu: mapedData.get(d.startDate).pcviewmenu + d.pcviewmenu,
            schlocationtap: mapedData.get(d.startDate).schlocationtap + d.schlocationtap,
            schlocationtapcategory: mapedData.get(d.startDate).schlocationtapcategory + d.schlocationtapcategory,
            schlocationtapname: mapedData.get(d.startDate).schlocationtapname + d.schlocationtapname,
            schlocationtapother: mapedData.get(d.startDate).schlocationtapother + d.schlocationtapother,
          });
    });

    for (const [key, value] of mapedData) {
      if (key) {
        mapedArray.push(value);
      }
    }
    return mapedArray;
  }

  mapAppleInsightsData(graphData, dateLabels: string[], timePeriod: string) {
    const { periods } = timePeriod['value'];

    const schlocationtap = [];
    const pcview = [];
    const pctapdirection = [];
    const pctapwebsite = [];
    const pctapcall = [];
    const pctapshare = [];
    const pctapshowcase = [];
    const pcviewmenu = [];
    const pctaporder = [];
    const pcorderdelivery = [];
    const pcorderfood = [];
    const pcordertakeout = [];
    const pcpickup = [];
    const pcreserveparking = [];
    const pcreservetable = [];
    const pcjoinwaitlist = [];
    const pcscheduleappt = [];
    const labels = [];
    const pcviewavail = [];

    let isGraphSingleBar: boolean;
    isGraphSingleBar =
      timePeriod === 'day_previous' ||
      timePeriod === 'week_current' ||
      timePeriod === 'week_previous' ||
      timePeriod === 'month_current' ||
      timePeriod === 'month_previous'
        ? true
        : false;
    const newArr = isGraphSingleBar
      ? this.mapAppleStringsToNumber(graphData)
      : this.removeAppleDuplicates(this.mapAppleStringsToNumber(graphData));

    newArr?.forEach((el) => {
      if (periods === 4 || periods === 8) {
        const month = el.startDate?.split('-')[1];
        const label = this.getQuarterLabel(this.getMonthName(month)) + ' ' + el.startDate?.split('-')[0];
        labels.push(label);
      } else {
        labels.push(el.startDate);
      }
      pcviewavail.push(el.pcviewavail);
      schlocationtap.push(el.schlocationtap);
      pcview.push(el.pcview);
      pctapdirection.push(el.pctapdirection);
      pctapwebsite.push(el.pctapwebsite);
      pctapcall.push(el.pctapcall);
      pctapshare.push(el.pctapshare);
      pctapshowcase.push(el.pctapshowcase);
      pcscheduleappt.push(el.pcscheduleappt);
      pcviewmenu.push(el.pcviewmenu);
      pctaporder.push(el.pctaporder);
      pcorderdelivery.push(el.pcorderdelivery);
      pcorderfood.push(el.pcorderfood);
      pcordertakeout.push(el.pcordertakeout);
      pcpickup.push(el.pcpickup);
      pcreserveparking.push(el.pcreserveparking);
      pcreservetable.push(el.pcreservetable);
      pcjoinwaitlist.push(el.pcjoinwaitlist);
    });

    return [
      {
        id: 'searchCard',
        title: 'Search Clicks Total',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Search clicks',
              backgroundColor: '#791e6c',
              ...this.graphConfigs,
              data: schlocationtap,
            },
          ],
        },
      },
      {
        id: 'views',
        title: 'Views',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Views',
              backgroundColor: '#491d8b',
              ...this.graphConfigs,
              data: pcview,
            },
          ],
        },
      },
      {
        id: 'directions',
        title: 'Directions',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Directions',
              backgroundColor: '#F68A8A',
              ...this.graphConfigs,
              data: pctapdirection,
            },
          ],
        },
      },
      {
        id: 'websiteClicks',
        title: 'Website clicks',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Website clicks',
              backgroundColor: '#e8e52e',
              ...this.graphConfigs,
              data: pctapwebsite,
            },
          ],
        },
      },
      {
        id: 'calls',
        title: 'Calls',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Calls',
              backgroundColor: '#6e44ad',
              ...this.graphConfigs,
              data: pctapcall,
            },
          ],
        },
      },
      {
        id: 'shares',
        title: 'Shares',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Shares',
              backgroundColor: '#8a3ffc',
              ...this.graphConfigs,
              data: pctapshare,
            },
          ],
        },
      },
      {
        id: 'showCaseClicks',
        title: 'Showcase Clicks',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Show case',
              backgroundColor: '#651e7d',
              ...this.graphConfigs,
              data: pctapshowcase,
            },
          ],
        },
      },
      {
        id: 'appointments',
        title: 'Appointments',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Appointments',
              backgroundColor: '#cf44be',
              ...this.graphConfigs,
              data: pcscheduleappt,
            },
          ],
        },
      },
      {
        id: 'menuCard',
        title: 'Menu',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Menu',
              backgroundColor: '#d1548c',
              ...this.graphConfigs,
              data: pcviewmenu,
            },
          ],
        },
      },
      {
        id: 'ordering',
        title: 'Ordering',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Order',
              backgroundColor: '#206A02',
              ...this.graphConfigs,
              data: pctaporder,
            },
            {
              type: 'bar',
              label: 'Delivery',
              backgroundColor: '#609c51',
              ...this.graphConfigs,
              data: pcorderdelivery,
            },
            {
              type: 'bar',
              label: 'Order food',
              backgroundColor: '#266317',
              ...this.graphConfigs,
              data: pcorderfood,
            },
            {
              type: 'bar',
              label: 'Order takeout',
              backgroundColor: '#64f241',
              ...this.graphConfigs,
              data: pcordertakeout,
            },
            {
              type: 'bar',
              label: 'Order pickup',
              backgroundColor: '#317d1e',
              ...this.graphConfigs,
              data: pcpickup,
            },
          ],
        },
      },
      {
        id: 'reservations',
        title: 'Reservations',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Reserve parking',
              backgroundColor: '#abdadb',
              ...this.graphConfigs,
              data: pcreserveparking,
            },
            {
              type: 'bar',
              label: 'Reserve table',
              backgroundColor: '#4b797a',
              ...this.graphConfigs,
              data: pcreservetable,
            },
            {
              type: 'bar',
              label: 'Join waitlist',
              backgroundColor: '#1cc6c9',
              ...this.graphConfigs,
              data: pcjoinwaitlist,
            },
          ],
        },
      },
    ];
  }

  private mapReviewsInsightsStringsToNumber(data) {
    return data.map((el) => {
      return {
        avgRating: Number.isNaN(+el?.average_rating) ? 0 : +el?.average_rating,
        avgRatingChange: Number.isNaN(+el?.average_rating_change) ? 0 : +el?.average_rating_change,
        totalNegativeRating: Number.isNaN(+el?.total_negative_ratings) ? 0 : +el?.total_negative_ratings,
        totalNeutralRating: Number.isNaN(+el?.total_neutral_ratings) ? 0 : +el?.total_neutral_ratings,
        totalPositiveRating: Number.isNaN(+el?.total_positive_ratings) ? 0 : +el?.total_positive_ratings,
        totalRating: Number.isNaN(+el?.total_ratings) ? 0 : +el?.total_ratings,
        startDate: el.period_start_date,
      };
    });
  }

  private removeReviewsInsightsAppleDuplicates(data) {
    const mapedData = new Map();
    const mapedArray = [];
    data.forEach((d) => {
      !mapedData.has(d.startDate)
        ? mapedData.set(d.startDate, d)
        : mapedData.set(d.startDate, {
            ...mapedData.get(d.startDate),
            avgRating: mapedData.get(d.startDate).avgRating + d.avgRating,
            avgRatingChange: mapedData.get(d.startDate).avgRatingChange + d.avgRatingChange,
            totalNegativeRating: mapedData.get(d.startDate).totalNegativeRating + d.totalNegativeRating,
            totalNeutralRating: mapedData.get(d.startDate).totalNeutralRating + d.totalNeutralRating,
            totalPositiveRating: mapedData.get(d.startDate).totalPositiveRating + d.totalPositiveRating,
            totalRating: mapedData.get(d.startDate).totalRating + d.totalRating,
          });
    });

    for (const [key, value] of mapedData) {
      if (key) {
        mapedArray.push(value);
      }
    }
    return mapedArray;
  }

  public mapReviewsInsights(graphData, dateLabels: string[], timePeriod: string) {
    const { periods } = timePeriod['value'];

    let avgRating: number[] = [];
    let avgRatingChange: number[] = [];
    let totalNegativeRating: number[] = [];
    let totalNeutralRating: number[] = [];
    let totalPositiveRating: number[] = [];
    let totalRating: number[] = [];
    let labels: string[] = [];

    let isGraphSingleBar: boolean;
    isGraphSingleBar =
      timePeriod === 'day_previous' ||
      timePeriod === 'week_current' ||
      timePeriod === 'week_previous' ||
      timePeriod === 'month_current' ||
      timePeriod === 'month_previous'
        ? true
        : false;
    const newArr = isGraphSingleBar
      ? this.mapReviewsInsightsStringsToNumber(graphData)
      : this.removeReviewsInsightsAppleDuplicates(this.mapReviewsInsightsStringsToNumber(graphData));

    newArr.forEach((el) => {
      avgRating.push(el.avgRating), avgRatingChange.push(el.avgRatingChange);
      totalNegativeRating.push(el.totalNegativeRating);
      totalNeutralRating.push(el.totalNeutralRating);
      totalPositiveRating.push(el.totalPositiveRating);
      totalRating.push(el.totalRating);
      if (periods === 4 || periods === 8) {
        const month = el.startDate?.split('-')[1];
        const label = this.getQuarterLabel(this.getMonthName(month)) + ' ' + el.startDate?.split('-')[0];
        labels.push(label);
      } else {
        labels.push(el.startDate);
      }
    });

    return [
      {
        id: 'avgRating',
        title: 'Average Ratings',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Average Ratings',
              backgroundColor: '#d1548c',
              data: avgRating,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'avgRatingChange',
        title: 'Average Rating Change',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Average Rating Change',
              backgroundColor: '#64f241',
              data: avgRatingChange,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'totalNegativeRating',
        title: 'Total Negative Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Negative Rating',
              backgroundColor: '#317d1e',
              data: totalNegativeRating,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'totalNeutralRating',
        title: 'Total Neutral Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Neutral Rating',
              backgroundColor: '#abdadb',
              data: totalNeutralRating,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'totalPositiveRating',
        title: 'Total Positive Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Positive Rating',
              backgroundColor: '#4b797a',
              data: totalPositiveRating,
              ...this.graphConfigs,
            },
          ],
        },
      },
      {
        id: 'totalRating',
        title: 'Total Rating',
        visibility: true,
        graphData: {
          labels: isGraphSingleBar ? dateLabels : labels,
          datasets: [
            {
              label: 'Total Rating',
              backgroundColor: '#1cc6c9',
              data: totalRating,
              ...this.graphConfigs,
            },
          ],
        },
      },
    ];
  }

  private mapYelpStringsToNumber(data) {
    return data.map((item) => {
      return {
        totalPageViews: Number.isNaN(+item?.page_views__total) ? 0 : +item?.page_views__total,
        directionsAndMapViews: Number.isNaN(+item?.direction_and_map_views__total)
          ? 0
          : +item?.direction_and_map_views__total,
        urlClicks: Number.isNaN(+item?.url_clicks__total) ? 0 : +item?.url_clicks__total,
        checkIns: Number.isNaN(+item?.check_ins__total) ? 0 : +item?.check_ins__total,
        userPhotos: Number.isNaN(+item?.user_photos__total) ? 0 : +item?.user_photos__total,
        bookmarks: Number.isNaN(+item?.bookmarks__total) ? 0 : +item?.bookmarks__total,
        desktopCTAClicks: Number.isNaN(+item?.desktop_cta_clicks__total) ? 0 : +item?.desktop_cta_clicks__total,
        mobileCTAClicks: Number.isNaN(+item?.mobile_cta_clicks__total) ? 0 : +item?.mobile_cta_clicks__total,
        desktopPageViews: Number.isNaN(+item?.desktop_page_views__total) ? 0 : +item?.desktop_page_views__total,
        mobilePageViews: Number.isNaN(+item?.mobile_page_views__total) ? 0 : +item?.mobile_page_views__total,
        trackingCalls: Number.isNaN(+item?.tracking_calls__total) ? 0 : +item?.tracking_calls__total,
        dealsSold: Number.isNaN(+item?.deals_sold__total) ? 0 : +item?.deals_sold__total,
        onlineOrders: Number.isNaN(+item?.online_orders__total) ? 0 : +item?.online_orders__total,
        checkInOfferRedemptions: Number.isNaN(+item?.check_in_offer_redemptions__total)
          ? 0
          : +item?.check_in_offer_redemptions__total,
        collectionItemAdded: Number.isNaN(+item?.collection_item_added__total)
          ? 0
          : +item?.collection_item_added__total,
        rapcInitiated: Number.isNaN(+item?.rapc_initiated__total) ? 0 : +item?.rapc_initiated__total,
        waitlistWisitCreated: Number.isNaN(+item?.waitlist_visit_created__total)
          ? 0
          : +item?.waitlist_visit_created__total,
        medianResponseTimeInSec: Number.isNaN(+item?.median_response_time_in_sec__total)
          ? 0
          : +item?.median_response_time_in_sec__total,
        replyRate: Number.isNaN(+item?.reply_rate__total) ? 0 : +item?.reply_rate__total,
        organicBizPageViews: Number.isNaN(+item?.organic_biz_page_views__total)
          ? 0
          : +item?.organic_biz_page_views__total,
        organicBizPageViewsPercentage: Number.isNaN(+item?.organic_biz_page_views_percentage__total)
          ? 0
          : +item?.organic_biz_page_views_percentage__total,
        startDate: item.period_start_date,
      };
    });
  }

  mapYelpInsightsData(graphData, dateLabels: string[], timePeriod: string) {
    const { periods } = timePeriod['value'];
    const labels = [];
    const totalPageViews = [];
    const directionsAndMapViews = [];
    const urlClicks = [];
    const checkIns = [];
    const userPhotos = [];
    const bookmarks = [];
    const desktopCTAClicks = [];
    const mobileCTAClicks = [];
    const desktopPageViews = [];
    const mobilePageViews = [];
    const trackingCalls = [];
    const dealsSold = [];
    const onlineOrders = [];
    const checkInOfferRedemptions = [];
    const collectionItemAdded = [];
    const rapcInitiated = [];
    const waitlistWisitCreated = [];
    const medianResponseTimeInSec = [];
    const replyRate = [];
    const organicBizPageViews = [];
    const organicBizPageViewsPercentage = [];

    const newArr = this.mapYelpStringsToNumber(graphData);

    newArr?.forEach((el) => {
      if (periods === 4 || periods === 8) {
        const month = el.startDate?.split('-')[1];
        const label = this.getQuarterLabel(this.getMonthName(month)) + ' ' + el.startDate?.split('-')[0];
        labels.push(label);
      } else {
        labels.push(el.startDate);
      }

      totalPageViews.push(el.totalPageViews);
      directionsAndMapViews.push(el.directionsAndMapViews);
      urlClicks.push(el.urlClicks);
      checkIns.push(el.checkIns);
      userPhotos.push(el.userPhotos);
      bookmarks.push(el.bookmarks);
      desktopCTAClicks.push(el.desktopCTAClicks);
      mobileCTAClicks.push(el.mobileCTAClicks);
      desktopPageViews.push(el.desktopPageViews);
      mobilePageViews.push(el.mobilePageViews);
      trackingCalls.push(el.trackingCalls);
      dealsSold.push(el.dealsSold);
      onlineOrders.push(el.onlineOrders);
      checkInOfferRedemptions.push(el.checkInOfferRedemptions);
      collectionItemAdded.push(el.collectionItemAdded);
      rapcInitiated.push(el.rapcInitiated);
      waitlistWisitCreated.push(el.waitlistWisitCreated);
      medianResponseTimeInSec.push(medianResponseTimeInSec);
      replyRate.push(replyRate);
      organicBizPageViews.push(organicBizPageViews);
      organicBizPageViewsPercentage.push(organicBizPageViewsPercentage);
    });

    return [
      {
        id: 'totalPageViews',
        title: 'Total User Views',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Map',
              backgroundColor: '#003a6d',
              ...this.graphConfigs,
              data: totalPageViews,
            },
          ],
        },
      },
      {
        id: 'directionsAndMapViews',
        title: 'Directions & Map Views',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Website',
              backgroundColor: '#791e6c',
              ...this.graphConfigs,
              data: directionsAndMapViews,
            },
          ],
        },
      },
      {
        id: 'urlClicks',
        title: 'Clicks to Website',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#206A02',
              ...this.graphConfigs,
              data: urlClicks,
            },
          ],
        },
      },
      {
        id: 'checkIns',
        title: 'Mobile Check-ins',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#791e6c',
              ...this.graphConfigs,
              data: checkIns,
            },
          ],
        },
      },
      {
        id: 'userPhotos',
        title: 'User Uploaded Photos',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#d51ba0',
              ...this.graphConfigs,
              data: userPhotos,
            },
          ],
        },
      },
      {
        id: 'bookmarks',
        title: 'Yelp Bookmarks',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#fa6bc1',
              ...this.graphConfigs,
              data: bookmarks,
            },
          ],
        },
      },
      {
        id: 'desktopCTAClicks',
        title: 'Desktop Call to Action Clicks',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#609c51',
              ...this.graphConfigs,
              data: desktopCTAClicks,
            },
          ],
        },
      },
      {
        id: 'mobileCTAClicks',
        title: 'Mobile Call to Action Clicks',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#266317',
              ...this.graphConfigs,
              data: mobileCTAClicks,
            },
          ],
        },
      },
      {
        id: 'desktopPageViews',
        title: 'Desktop User Views',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#e8e52e',
              ...this.graphConfigs,
              data: desktopPageViews,
            },
          ],
        },
      },
      {
        id: 'mobilePageViews',
        title: 'Mobile User Views',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#e85325',
              ...this.graphConfigs,
              data: mobilePageViews,
            },
          ],
        },
      },
      {
        id: 'trackingCalls',
        title: 'Calls Tracked',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#003a6d',
              ...this.graphConfigs,
              data: trackingCalls,
            },
          ],
        },
      },
      {
        id: 'dealsSold',
        title: 'Deals Sold',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#007eff',
              ...this.graphConfigs,
              data: dealsSold,
            },
          ],
        },
      },
      {
        id: 'onlineOrders',
        title: 'Online Orders',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#791e6c',
              ...this.graphConfigs,
              data: onlineOrders,
            },
          ],
        },
      },
      {
        id: 'checkInOfferRedemptions',
        title: 'Check In Offer Redemptions',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#fa6bc1',
              ...this.graphConfigs,
              data: checkInOfferRedemptions,
            },
          ],
        },
      },
      {
        id: 'collectionItemAdded',
        title: 'Collections',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#266317',
              ...this.graphConfigs,
              data: collectionItemAdded,
            },
          ],
        },
      },
      {
        id: 'rapcInitiated',
        title: 'RaPC Initiated',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#609c51',
              ...this.graphConfigs,
              data: rapcInitiated,
            },
          ],
        },
      },
      {
        id: 'waitlistWisitCreated',
        title: 'Waitlist Visit Created',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#64f241',
              ...this.graphConfigs,
              data: waitlistWisitCreated,
            },
          ],
        },
      },
      {
        id: 'medianResponseTimeInSec',
        title: 'Median response time (secs)',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#491d8b',
              ...this.graphConfigs,
              data: medianResponseTimeInSec,
            },
          ],
        },
      },
      {
        id: 'replyRate',
        title: 'Reply rate',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#F68A8A',
              ...this.graphConfigs,
              data: replyRate,
            },
          ],
        },
      },
      {
        id: 'organicBizPageViews',
        title: 'Organic Page Visits',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#6e44ad',
              ...this.graphConfigs,
              data: organicBizPageViews,
            },
          ],
        },
      },
      {
        id: 'organicBizPageViewsPercentage',
        title: '% Biz Page Views Organic',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Customer',
              backgroundColor: '#e8e52e',
              ...this.graphConfigs,
              data: organicBizPageViewsPercentage,
            },
          ],
        },
      },
    ];
  }

  private mapBingStringsToNumber(data) {
    return data.map((item) => {
      return {
        startDate: item.period_start_date,

        // Impressions cards data...
        desktopImpressionsData:
          (Number.isNaN(+item?.maps_desktop_impressions) ? 0 : +item?.maps_desktop_impressions) +
          (Number.isNaN(+item?.serp_desktop_impressions) ? 0 : +item?.serp_desktop_impressions),
        mobileImpressionsData:
          (Number.isNaN(+item?.maps_desktop_impressions) ? 0 : +item?.maps_mobile_impressions) +
          (Number.isNaN(+item?.serp_mobile_impressions) ? 0 : +item?.serp_mobile_impressions),

        // Clicks cards data...
        desktopClicksData:
          (Number.isNaN(+item?.maps_desktop_clicks) ? 0 : +item?.maps_desktop_clicks) +
          (Number.isNaN(+item?.serp_desktop_clicks) ? 0 : +item?.serp_desktop_clicks),
        mobileClicksData:
          (Number.isNaN(+item?.maps_mobile_clicks) ? 0 : +item?.maps_mobile_clicks) +
          (Number.isNaN(+item?.serp_mobile_clicks) ? 0 : +item?.serp_mobile_clicks),

        // Clicks Website cards data...
        desktopClicksWebsiteData:
          (Number.isNaN(+item?.maps_desktop_clicks_website) ? 0 : +item?.maps_desktop_clicks_website) +
          (Number.isNaN(+item?.serp_desktop_clicks_website) ? 0 : +item?.serp_desktop_clicks_website),
        mobileClicksWebsiteData:
          (Number.isNaN(+item?.maps_mobile_clicks_website) ? 0 : +item?.maps_mobile_clicks_website) +
          (Number.isNaN(+item?.serp_mobile_clicks_website) ? 0 : +item?.serp_mobile_clicks_website),

        // Clicks directions cards data...
        desktopClicksDirectionsData:
          (Number.isNaN(+item?.maps_mobile_clicks_direction) ? 0 : +item?.maps_mobile_clicks_direction) +
          (Number.isNaN(+item?.serp_desktop_clicks_direction) ? 0 : +item?.serp_desktop_clicks_direction),
        mobileClicksDirectionsData:
          (Number.isNaN(+item?.maps_mobile_clicks_direction) ? 0 : +item?.maps_mobile_clicks_direction) +
          (Number.isNaN(+item?.serp_mobile_clicks_direction) ? 0 : +item?.serp_mobile_clicks_direction),

        // Clicks phone cards data...
        desktopClicksPhoneData:
          (Number.isNaN(+item?.maps_desktop_clicks_phone) ? 0 : +item?.maps_desktop_clicks_phone) +
          (Number.isNaN(+item?.serp_desktop_clicks_phone) ? 0 : +item?.serp_desktop_clicks_phone),
        mobileClicksPhoneData:
          (Number.isNaN(+item?.maps_mobile_clicks_phone) ? 0 : +item?.maps_mobile_clicks_phone) +
          (Number.isNaN(+item?.serp_mobile_clicks_phone) ? 0 : +item?.serp_mobile_clicks_phone),

        // Clicks Photos cards data...
        desktopClicksPhotoData:
          (Number.isNaN(+item?.maps_desktop_clicks_photos) ? 0 : +item?.maps_desktop_clicks_photos) +
          (Number.isNaN(+item?.serp_desktop_clicks_photos) ? 0 : +item?.serp_desktop_clicks_photos),
        mobileClicksPhotoData:
          (Number.isNaN(+item?.maps_mobile_clicks_photos) ? 0 : +item?.maps_mobile_clicks_photos) +
          (Number.isNaN(+item?.serp_mobile_clicks_photos) ? 0 : +item?.serp_mobile_clicks_photos),

        // Clicks Located at cards data...
        desktopClicksLocatedAtData:
          (Number.isNaN(+item?.maps_desktop_clicks_locatedat) ? 0 : +item?.maps_desktop_clicks_locatedat) +
          (Number.isNaN(+item?.serp_desktop_clicks_locatedat) ? 0 : +item?.serp_desktop_clicks_locatedat),
        mobileClicksLocatedAtData:
          (Number.isNaN(+item?.maps_mobile_clicks_locatedat) ? 0 : +item?.maps_mobile_clicks_locatedat) +
          (Number.isNaN(+item?.serp_mobile_clicks_locatedat) ? 0 : +item?.serp_mobile_clicks_locatedat),

        // Clicks Review cards data...
        desktopClicksReviewsData:
          (Number.isNaN(+item?.maps_desktop_clicks_review) ? 0 : +item?.maps_desktop_clicks_review) +
          (Number.isNaN(+item?.serp_desktop_clicks_review) ? 0 : +item?.serp_desktop_clicks_review),
        mobileClicksReviewsData:
          (Number.isNaN(+item?.maps_mobile_clicks_review) ? 0 : +item?.maps_mobile_clicks_review) +
          (Number.isNaN(+item?.serp_mobile_clicks_review) ? 0 : +item?.serp_mobile_clicks_review),

        // Clicks Suggest an Edit cards data...
        desktopClicksSuggestAnEditData:
          (Number.isNaN(+item?.maps_desktop_clicks_suggestanedit) ? 0 : +item?.maps_desktop_clicks_suggestanedit) +
          (Number.isNaN(+item?.serp_desktop_clicks_suggestanedit) ? 0 : +item?.serp_desktop_clicks_suggestanedit),
        mobileClicksSuggestAnEditData:
          (Number.isNaN(+item?.maps_mobile_clicks_suggestanedit) ? 0 : +item?.maps_mobile_clicks_suggestanedit) +
          (Number.isNaN(+item?.serp_mobile_clicks_suggestanedit) ? 0 : +item?.serp_mobile_clicks_suggestanedit),

        // Clicks order online cards data
        desktopClicksOrderOnlineData:
          (Number.isNaN(+item?.maps_desktop_clicks_suggestanedit) ? 0 : +item?.maps_desktop_clicks_suggestanedit) +
          (Number.isNaN(+item?.serp_desktop_clicks_suggestanedit) ? 0 : +item?.serp_desktop_clicks_suggestanedit),
        mobileClicksOrderOnlineData:
          (Number.isNaN(+item?.maps_mobile_clicks_suggestanedit) ? 0 : +item?.maps_mobile_clicks_suggestanedit) +
          (Number.isNaN(+item?.serp_mobile_clicks_suggestanedit) ? 0 : +item?.serp_mobile_clicks_suggestanedit),

        // Clicks Menu cards data
        desktopClicksMenuData:
          (Number.isNaN(+item?.maps_desktop_clicks_suggestanedit) ? 0 : +item?.maps_desktop_clicks_suggestanedit) +
          (Number.isNaN(+item?.serp_desktop_clicks_suggestanedit) ? 0 : +item?.serp_desktop_clicks_suggestanedit),
        mobileClicksMenuData:
          (Number.isNaN(+item?.maps_mobile_clicks_suggestanedit) ? 0 : +item?.maps_mobile_clicks_suggestanedit) +
          (Number.isNaN(+item?.serp_mobile_clicks_suggestanedit) ? 0 : +item?.serp_mobile_clicks_suggestanedit),
      };
    });
  }

  mapBingInsightsData(graphData, dateLabels: string[], timePeriod: string) {
    const { periods } = timePeriod['value'];
    let desktopImpressions: number[] = [];
    let mobileImpressions: number[] = [];
    let desktopClicks: number[] = [];
    let mobileClicks: number[] = [];
    let desktopClicksWebsite: number[] = [];
    let mobileClicksWebsite: number[] = [];
    let desktopClicksDirections: number[] = [];
    let mobileClicksDirections: number[] = [];
    let desktopClicksPhone: number[] = [];
    let mobileClicksPhone: number[] = [];
    let desktopClicksPhoto: number[] = [];
    let mobileClicksPhoto: number[] = [];
    let desktopClicksLocatedAt: number[] = [];
    let mobileClicksLocatedAt: number[] = [];
    let desktopClicksReviews: number[] = [];
    let mobileClicksReviews: number[] = [];
    let desktopClicksSuggestAnEdit: number[] = [];
    let mobileClicksSuggestAnEdit: number[] = [];
    let desktopClicksOrderOnline: number[] = [];
    let mobileClicksOrderOnline: number[] = [];
    let desktopClicksMenu: number[] = [];
    let mobileClicksMenu: number[] = [];
    let labels: string[] = [];

    const newArr =  this.mapBingStringsToNumber(graphData);

    newArr.forEach((el) => {
      desktopImpressions.push(el.desktopImpressionsData);
        mobileImpressions.push(el.mobileImpressionsData);
        desktopClicks.push(el.desktopClicksData);
        mobileClicks.push(el.mobileClicksData);
        desktopClicksWebsite.push(el.desktopClicksWebsiteData);
        mobileClicksWebsite.push(el.mobileClicksWebsiteData);
        desktopClicksDirections.push(el.desktopClicksDirectionsData);
        mobileClicksDirections.push(el.mobileClicksDirections);
      desktopClicksPhone.push(el.desktopClicksPhoneData);
        mobileClicksPhone.push(el.mobileClicksPhoneData);
        desktopClicksPhoto.push(el.desktopClicksPhotoData);
        mobileClicksPhoto.push(el.mobileClicksPhotoData);
        desktopClicksLocatedAt.push(el.desktopClicksLocatedAtData);
        mobileClicksLocatedAt.push(el.mobileClicksLocatedAtData);
        desktopClicksReviews.push(el.desktopClicksReviewsData);
        mobileClicksReviews.push(el.mobileClicksReviewsData);
        desktopClicksSuggestAnEdit.push(el.desktopClicksSuggestAnEditData);
        mobileClicksSuggestAnEdit.push(el.mobileClicksSuggestAnEditData);
        desktopClicksOrderOnline.push(el.desktopClicksOrderOnlineData);
        mobileClicksOrderOnline.push(el.mobileClicksOrderOnlineData);
        desktopClicksMenu.push(el.desktopClicksMenuData);
        mobileClicksMenu.push(el.mobileClicksMenuData);
        if (periods === 4 || periods === 8) {
          const month = el.startDate?.split('-')[1];
          const label = this.getQuarterLabel(this.getMonthName(month)) + ' ' + el.startDate?.split('-')[0];
          labels.push(label);
        } else {
          labels.push(el.startDate);
        }
    });

    return [
      {
        id: 'impressions',
        title: 'Impressions',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop Imressions',
              backgroundColor: '#791e6c',
              ...this.graphConfigs,
              data: [...desktopImpressions],
            },
            {
              type: 'bar',
              label: 'Mobile Impressions',
              backgroundColor: '#fa6bc1',
              ...this.graphConfigs,
              data: [...mobileImpressions],
            },
          ],
        },
      },
      {
        id: 'clicks',
        title: 'Clicks',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop Clicks',
              backgroundColor: '#491d8b',
              ...this.graphConfigs,
              data: [...desktopClicks],
            },
            {
              type: 'bar',
              label: 'Mobile Clicks',
              backgroundColor: '#491d00',
              ...this.graphConfigs,
              data: [...mobileClicks],
            },
          ],
        },
      },
      {
        id: 'clicksWebsite',
        title: 'Clicks: Website',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop clicks website',
              backgroundColor: '#F68A8A',
              ...this.graphConfigs,
              data: [...desktopClicksWebsite],
            },
            {
              type: 'bar',
              label: 'Mobile clicks website',
              backgroundColor: '#F68A8A',
              ...this.graphConfigs,
              data: [...mobileClicksWebsite],
            },
          ],
        },
      },
      {
        id: 'clicksDirections',
        title: 'Clicks: Directions',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop clicks directions',
              backgroundColor: '#e8e52e',
              ...this.graphConfigs,
              data: [...desktopClicksDirections],
            },
            {
              type: 'bar',
              label: 'Mobile clicks directions',
              backgroundColor: '#e8e52e',
              ...this.graphConfigs,
              data: [...mobileClicksDirections],
            },
          ],
        },
      },
      {
        id: 'clicksPhone',
        title: 'Clicks: Phone',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop clicks phone',
              backgroundColor: '#6e44ad',
              ...this.graphConfigs,
              data: [...desktopClicksPhone],
            },
            {
              type: 'bar',
              label: 'Mobile clicks phone',
              backgroundColor: '#6e44ad',
              ...this.graphConfigs,
              data: [...mobileClicksPhone],
            },
          ],
        },
      },
      {
        id: 'clicksPhotos',
        title: 'Clicks: Photos',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Clicks photos desktop',
              backgroundColor: '#8a3ffc',
              ...this.graphConfigs,
              data: [...desktopClicksPhoto],
            },
            {
              type: 'bar',
              label: 'Clicks photos mobile',
              backgroundColor: '#8a3ffc',
              ...this.graphConfigs,
              data: [...mobileClicksPhoto],
            },
          ],
        },
      },
      {
        id: 'clicksLocatedAt',
        title: 'Clicks: Located at',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Show case',
              backgroundColor: '#651e7d',
              ...this.graphConfigs,
              data: [...desktopClicksLocatedAt],
            },
            {
              type: 'bar',
              label: 'Show case',
              backgroundColor: '#651e7d',
              ...this.graphConfigs,
              data: [...mobileClicksLocatedAt],
            },
          ],
        },
      },
      {
        id: 'clicksReview',
        title: 'Clicks: Review',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Clicks review desktop',
              backgroundColor: '#cf44be',
              ...this.graphConfigs,
              data: [...desktopClicksReviews],
            },
            {
              type: 'bar',
              label: 'Mobile clicks review',
              backgroundColor: '#cf44be',
              ...this.graphConfigs,
              data: [...mobileClicksReviews],
            },
          ],
        },
      },
      {
        id: 'clicksSuggestAnEdit',
        title: 'Clicks Suggest an Edit',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop clicks suggest an edit',
              backgroundColor: '#d1548c',
              ...this.graphConfigs,
              data: [...desktopClicksSuggestAnEdit],
            },
            {
              type: 'bar',
              label: 'Mobile clicks suggest an edit',
              backgroundColor: '#d1548c',
              ...this.graphConfigs,
              data: [...mobileClicksSuggestAnEdit],
            },
          ],
        },
      },
      {
        id: 'clicksOrderOnline',
        title: 'Clicks Order Online',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Desktop clicks order online',
              backgroundColor: '#206A02',
              ...this.graphConfigs,
              data: [...desktopClicksOrderOnline],
            },
            {
              type: 'bar',
              label: 'Mobile clicks order online',
              backgroundColor: '#609c51',
              ...this.graphConfigs,
              data: [...mobileClicksOrderOnline],
            },
          ],
        },
      },
      {
        id: 'clicksMenu',
        title: 'Clicks Menu',
        visibility: true,
        graphData: {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Clicks menu desktop',
              backgroundColor: '#abdadb',
              ...this.graphConfigs,
              data: [...desktopClicksMenu],
            },
            {
              type: 'bar',
              label: 'Clicks menu mobile',
              backgroundColor: '#4b797a',
              ...this.graphConfigs,
              data: [...mobileClicksMenu],
            },
          ],
        },
      },
    ];
  }

  getMonthName(month: number) {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'][month - 1];
  };

  getQuarterLabel(month): string {
    switch (month) {
      case 'Jan':
        return 'Q1';
      case 'Apr':
        return 'Q2';
      case 'Jul':
        return 'Q3';
      case 'Oct':
        return 'Q4';
    }
  }

}
