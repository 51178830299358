import { Injectable } from "@angular/core";
import { IReviewData } from "../review-management.interfaces";

@Injectable({
  providedIn: 'root'
})

export class ReviewsCopyClipboardService {

  public mapReviewDataCopyObject(review: IReviewData): any {
    const addressLine = review?.addressLines?.length ? review?.addressLines.join(', ') : '';
    const city = review?.city || '';
    const state = review?.state || '';
    const postalCode = review?.postalCode || '';
    const address = addressLine && city && state && postalCode && `${addressLine} ${city} ${state} ${postalCode}` || 'N/A';

    const reviewCreatedDay = review?.reviewCreatedAt?._day_ || '';
    const reviewCreatedMonth = review?.reviewCreatedAt?._month_ || '';
    const reviewCreatedYear = review?.reviewCreatedAt?._year_ || '';
    const reviewCreatedDate = reviewCreatedDay && reviewCreatedMonth && reviewCreatedYear && `${reviewCreatedMonth}-${reviewCreatedDay}-${reviewCreatedYear}` || 'N/A';

    const reviewUpdatedDay = review?.reviewCreatedAt?._day_ || '';
    const reviewUpdatedMonth = review?.reviewCreatedAt?._month_ || '';
    const reviewUpdatedYear = review?.reviewCreatedAt?._year_ || '';
    const reviewUpdatedDate = reviewUpdatedDay && reviewUpdatedMonth && reviewUpdatedYear && `${reviewUpdatedMonth}-${reviewUpdatedDay}-${reviewUpdatedYear}` || 'N/A';

    const responseDay = review?.replyUpdatedAt?._day_ || '';
    const reposnseMonth = review?.replyUpdatedAt?._month_ || '';
    const responseYear = review?.replyUpdatedAt?._year_ || '';
    const responseDate = responseDay && reposnseMonth && responseYear && `${reposnseMonth}-${responseDay}-${responseYear}` || 'N/A';

    return {
      storeCode: review?.storeCode || 'N/A',
      businessName: review?.businessName || 'N/A',
      address: address,
      reviewCreatedDate: reviewCreatedDate,
      reviewUpdatedDate: reviewUpdatedDate,
      reviewText: review?.reviewerComment || 'N/A',
      reviewerName: review?.reviewerName || 'N/A',
      rating: review?.starRating || 'N/A',
      responseDate: responseDate,
      responseText: review?.replyComment || 'N/A',
      reviewSource: review?.vendorIdent || 'N/A',
    };
  };

  public mapReviewsDataCopyString(reviewsData): string {
    return `
    Store code: ${reviewsData.storeCode}
    Business name: ${reviewsData.businessName}
    Address: ${reviewsData.address}
    Review created date: ${reviewsData.reviewCreatedDate}
    Review updated date: ${reviewsData.reviewUpdatedDate}
    Review text: ${reviewsData.reviewText}
    Reviewer name: ${reviewsData.reviewerName}
    Rating: ${reviewsData.rating}
    Response date: ${reviewsData.responseDate}
    Response text: ${reviewsData.responseText}
    Review source: ${reviewsData.reviewSource}
  `;
  }
}
