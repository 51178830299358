import { Injectable } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, forkJoin, from, Subject } from "rxjs";
import { SocketService } from "src/app/core/backend-adapter/socket.service";

@Injectable({
  providedIn: 'root'
})

export class AppleMapsInsightsDataService {
  public showReviews: boolean = true;
  public dialogRefComponent: DynamicDialogRef | undefined;
  public a$: Subject<string[]> = new Subject<string[]>();
  public graphData = [];

  private updateFilterState = new BehaviorSubject([]);
  public currentUpdatedFilterState = this.updateFilterState.asObservable();

  private filterOperandType = new BehaviorSubject('-and');
  public currentFilterOperandType = this.filterOperandType.asObservable();

  public filterOperand: string;

  constructor(private socketService: SocketService,) { }

  getMonthName(month: number): string {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'August', 'Sept', 'Oct', 'Nov', 'Dec'][month - 1];
  }

  generatetGraphData(currData, prevData?) {
    const labels = [];
    const viewsMaps = [];
    const viewsSrch = [];
    const actionsWeb = [];
    const actionsPhone = [];
    const actionsDirs = [];
    const viewsMapsDesktop = [];
    const viewsMapsMobile = [];
    const viewsSearchDesktop = [];
    const viewsSearchMobile = [];
    const conversations = [];
    const actionsDrivingDirections = [];
    const actionsWebsite = [];
    const bookings = [];
    const foodOrders = [];


    for (const item of currData) {
      if (prevData) {
        // Monthly calls eg: 3 and 6 months.
        const label = this.getMonthName(item.month) + ' ' + item.year.toString();
        labels.push(label);
      } else {
        // Quarterly calls
        const label = 'Q' + item.qtr + ' ' + item.year.toString();
        labels.push(label);
      }

      viewsMaps.push(item.viewsMaps);
      viewsSrch.push(item.viewsSearch);

      actionsWeb.push(item.actionsWebsite);
      actionsPhone.push(item.actionsPhone);
      actionsDirs.push(item.actionsDrivingDirections);

      viewsMapsDesktop.push(item.viewsMapsDesktop);
      viewsMapsMobile.push(item.viewsMapsMobile);
      viewsSearchDesktop.push(item.viewsSearchDesktop);
      viewsSearchMobile.push(item.viewsSearchMobile);
      conversations.push(item?.conversations);
      actionsDrivingDirections.push(item?.actionsDrivingDirections);
      actionsWebsite.push(item?.actionsWebsite);
      bookings.push(item?.bookings);
      foodOrders.push(item?.foodOrders);
    }

    return {
      labels,
      viewsMaps,
      viewsSrch,
      actionsWeb,
      actionsPhone,
      actionsDirs,
      viewsMapsDesktop,
      viewsMapsMobile,
      viewsSearchDesktop,
      viewsSearchMobile,
      conversations,
      actionsDrivingDirections,
      actionsWebsite,
      bookings,
      foodOrders,
    };
  }

  // Zip codes code...
  public getZipCodes() {
    const data = new Array(20).fill({
      city: 'Hoover',
      zipcode: '35244',
      state: 'Alabama',
      score: Math.floor(Math.random() * 100)
    });

    return data;
  };


  public getDialogRef(ref: DynamicDialogRef): void {
    this.dialogRefComponent = ref;
  }

  public setBasicFiltersData() {
    return [
      {
        id: 0,
        label: 'Store code',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter Store code',
        toolTipText: 'Please press \'Enter\' to add this item'
      }
    ];
  };

  public setAdvanceFiltersData(): any[] {
    return [
      {
        id: 'business_names',
        label: 'Business Name',
        fieldType: 'checkbox',
        value: [],
        checkboxList: [],
      },
      {
        id: 'city',
        label: 'City',
        fieldType: 'chipInput',
        value: [],
        placeholder: 'Enter City name. Eg:- Montreal',
        toolTipText: 'Please press \'Enter\' to add this item'
      },
      {
        id: 'states',
        label: 'State',
        fieldType: 'checkbox',
        value: [],
        checkboxList: [],
      },
      {
        id: 'countries',
        label: 'Country',
        fieldType: 'checkbox',
        value: [],
        checkboxList: [],
      },
      {
        id: 'tag_list',
        label: 'Tag',
        fieldType: 'checkbox',
        checkboxList: [],
        value: [],
      },
      {
        id: 'label_list',
        label: 'Google Label',
        fieldType: 'checkbox',
        checkboxList: [],
        value: [],
      },
      {
        id: 'category_list',
        label: 'Business Category',
        fieldType: 'checkbox',
        checkboxList: [],
        value: []
      },
    ];
  };

  getSelectedFiltersList(list) {
    this.a$.next(list);

  }

  public mapInsightsData(coll, range) {
    const schlocationtap = [];
    const pcview = [];
    const pctapdirection = [];
    const pctapwebsite = [];
    const pctapcall = [];
    const pctapshare = [];
    const pctapshowcase = [];
    const pcviewmenu = [];
    const pctaporder = [];
    const pcorderdelivery = [];
    const pcorderfood = [];
    const pcordertakeout = [];
    const pcpickup = [];
    const pcreserveparking = [];
    const pcreservetable = [];
    const pcjoinwaitlist = [];
    const pcscheduleappt = [];
    const labels = [];
    const pcviewavail = [];

    if (coll && coll?.length) {
      coll.forEach(item => {
        const month =  item?.period_start_date?.split('-')[1];
        const label = range === 4 ?
          (this.getQuarterLabel(this.getMonthName(month)) + ' ' + item?.period_start_date?.split('-')[0]) :
          this.getMonthName(month) + ' ' + item?.period_start_date?.split('-')[0];
        labels.push(label);
        pcviewavail.push(Number.isNaN(+item?.placecard_actions__view_availability) ? 0 : (+item?.placecard_actions__view_availability))
        schlocationtap.push(  Number.isNaN(+item?.search_clicks__total) ? 0 : (+item?.search_clicks__total));
        pcview.push(  Number.isNaN(+item?.placecard_views) ? 0 : (+item?.placecard_views));
        pctapdirection.push(  Number.isNaN(+item?.placecard_actions__directions) ? 0 : (+item?.placecard_actions__directions));
        pctapwebsite.push(  Number.isNaN(+item?.placecard_actions__website_click) ? 0 : (+item?.placecard_actions__website_click));
        pctapcall.push(  Number.isNaN(+item?.placecard_actions__calls) ? 0 : (+item?.placecard_actions__calls));
        pctapshare.push(  Number.isNaN(+item?.placecard_actions__shares) ? 0 : (+item?.placecard_actions__shares));
        pctapshowcase.push(  Number.isNaN(+item?.placecard_actions__showcase_clicks) ? 0 : (+item?.placecard_actions__showcase_clicks));
        pcscheduleappt.push(  Number.isNaN(+item?.placecard_actions__appointments) ? 0 : (+item?.placecard_actions__appointments));
        pcviewmenu.push(  Number.isNaN(+item?.placecard_actions__view_menu) ? 0 : (+item?.placecard_actions__view_menu));
        pctaporder.push(  Number.isNaN(+item?.placecard_actions__orders) ? 0 : (+item?.placecard_actions__orders));
        pcorderdelivery.push(  Number.isNaN(+item?.placecard_actions__delivery_orders) ? 0 : (+item?.placecard_actions__delivery_orders));
        pcorderfood.push(  Number.isNaN(+item?.placecard_actions__food_orders) ? 0 : (+item?.placecard_actions__food_orders));
        pcordertakeout.push(  Number.isNaN(+item?.placecard_actions__takeout_orders) ? 0 : (+item?.placecard_actions__takeout_orders));
        pcpickup.push(  Number.isNaN(+item?.placecard_actions__pickup) ? 0 : (+item?.placecard_actions__pickup));
        pcreserveparking.push(  Number.isNaN(+item?.placecard_actions__reserve_parking) ? 0 : (+item?.placecard_actions__reserve_parking));
        pcreservetable.push(  Number.isNaN(+item?.placecard_actions__reserve_table) ? 0 : (+item?.placecard_actions__reserve_table));
        pcjoinwaitlist.push(  Number.isNaN(+item?.placecard_actions__join_waitlist) ? 0 : (+item?.placecard_actions__join_waitlist));
      });
    };

    return {
      schlocationtap,
      pcview,
      pctapdirection,
      pctapwebsite,
      pctapcall,
      pctapshare,
      pctapshowcase,
      pcviewmenu,
      pctaporder,
      pcorderdelivery,
      pcorderfood,
      pcordertakeout,
      pcpickup,
      pcreserveparking,
      pcreservetable,
      pcjoinwaitlist,
      pcscheduleappt,
      labels
    }
  };

  public setCurrentFilterState(currFilters: any): void {
    this.updateFilterState.next(currFilters);
  }

  public buildWhereClaus(finalFilter: any[] = []): any[] {
    const filterClauses: string[][] = [];
    const tempFilterArr: string[] = [];

    const pushQueriesToMainArray = () => {
      let filterClause: Array<string> = ['-or'];
      filterClause = filterClause.concat(tempFilterArr);
      filterClauses.push(filterClause);
      tempFilterArr.length = 0;
    };

    finalFilter.forEach((fl: { key: string, value: any }) => {
      for (const [key, value] of Object.entries(fl)) {
        switch (key) {
          case 'Business Name': // Business Name
            value.forEach(v => { tempFilterArr.push(`businessName::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'Store code': // Store code
            value.forEach(v => { tempFilterArr.push(`storeCode::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'City': // City
            value.forEach(v => { tempFilterArr.push(`city::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'State': // State
            value.forEach(v => { tempFilterArr.push(`state::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'Country': // Country
            value.forEach(v => { tempFilterArr.push(`country::text ~* '\\y${v}'`) });
            pushQueriesToMainArray();
            break;
          case 'Tag': // Tag
            value.forEach(v => { tempFilterArr.push(`tags::text LIKE '%${v}%'`) })
            pushQueriesToMainArray();
            break;
          case 'Google Label': // Google label
            value.forEach(v => { tempFilterArr.push(`labels::text LIKE '%${v}%'`) })
            pushQueriesToMainArray();
            break;
          case 'Business Category': // Business Category
            value.forEach(v => { tempFilterArr.push(`categories::text LIKE '%${v}%'`) })
            pushQueriesToMainArray();
            break;
        }
      }
    });

    let whereClauseArray: Array<any> = [this.filterOperandType.getValue()];
    whereClauseArray = whereClauseArray.concat(filterClauses);

    return whereClauseArray;
  }

  public setFilterOperandType(operandType: string): void {
    this.filterOperandType.next(operandType);
    this.filterOperand = operandType;
  }

  public get getFilterOperandType(): string {
    return this.filterOperand;
  }

  private getQuarterLabel(month): string {
    switch (month) {
      case 'Jan':
        return 'Q1';
      case 'Apr':
        return 'Q2';
      case 'Jul':
        return 'Q3';
      case 'Oct':
        return 'Q4';
    }
  }

}
