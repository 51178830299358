import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { generateJWTToken } from 'src/app/core/helpers/jwt-token.utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdvanceFiltersService {
  constructor(private http: HttpClient) {}

  /**
   * @description To get the list of all Advance filters on Insights page.
   * @param accountId account id.
   * @param userId user id.
   * @returns observable.
   */
  public getAdvanceFiltersLists(accountId: number, userId: number): Observable<any> {
    let url: string;
    const payloadObj = {
      iss: 'https://platform.renderseo.com',
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };

    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);

    url =
      environment.name === 'local' || environment.name === 'development'
        ? `https://dev-io.renderseo.com/insights/filters`
        : `https://io.renderseo.com/insights/filters`;

    return this.http.get<Observable<any>>(url, { headers: headers });
  }
}
