<div class="reports_wrapper">
  <div class="tabs">
    <div class="tabs_block">
      <button
        [class.isActive]="displayCardInsights"
        [class.isInActive]="!displayCardInsights"
        class="tabs_block__btn"
        (click)="openTab()"
        >Place Card Insights
      </button>
      <button
        [class.isActive]="displaySpatialInsights"
        [class.isInActive]="!displaySpatialInsights"
        class="tabs_block__btn spatial_insights"
        (click)="openTab()"
        >Spatial Insights
      </button>
    </div>
  </div>

  <div class="reports_wrapper__insights" *ngIf="displayCardInsights">
    <div class="insights_block">
      <h3 class="insights_title">APPLE INSIGHTS</h3>
      <div class="insights_filters">
        <p-dropdown [options]="dateRanges" [(ngModel)]="selectedRange" optionLabel="name" optionValue="value"
          (onChange)="dateRangeChanged($event)">
        </p-dropdown>
        <p-splitButton
          #actions
          label="Filter by"
          [model]="tags"
          (onClick)="actions.onDropdownButtonClick(null)"
          styleClass="p-button-sm">
        </p-splitButton>
        <button pButton class="edit p-button-text" icon="fa fa-pen" (click)="editInsights()"></button>
      </div>
    </div>

    <div class="card_holder" [class.full_width]="fullWidth" [class.half_width]="!fullWidth">
      <ng-container *ngFor="let data of demoData">
        <div class="chart" *ngIf="data.visibility" [class.full_width__chart]="fullWidth" [class.half_width__chart]="!fullWidth">
          <div class="chart_block">
            <h2 class="chart_block_title">{{ data.title }}</h2>
            <p-chart type="bar" [data]="data.graphData" [options]="insightsGraphConfig" width="100%"
              [height]="showReviews ? '18vh' : '22vh'">
            </p-chart>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>

<div class="reports_wrapper__insights spatial_insights" *ngIf="displaySpatialInsights">
  <div class="insights_block">
    <h3 class="insights_title">APPLE INSIGHTS</h3>
    <div class="insights_filters">
      <!-- Locations dropdown -->
      <p-dropdown [options]="locations" [(ngModel)]="selectedLocation" optionLabel="businessName" [filter]="true"
        (onChange)="changeLocation()" [virtualScroll]="true">
      </p-dropdown>
      <!-- Calendar -->
      <p-calendar [showIcon]="true" [(ngModel)]="date" view="month" dateFormat="mm/yy"
        (onSelect)="changeMonth()">
      </p-calendar>
    </div>
  </div>

  <div class="table_card">
    <app-insights-table-card class="table_card_block" [title]="cardTitle1" [cardDescription]="CardDescription"
      [zipCodes]="searchLocationsZipCodes">
    </app-insights-table-card>

    <app-insights-table-card class="table_card_block" [title]="cardTitle2" [cardDescription]="CardDescription"
      [zipCodes]="directionsLocationsZipCodes">
    </app-insights-table-card>
  </div>

</div>



<!-- Dialog code -->
<p-dialog id="appleInsights" header="Customize Insights" [(visible)]="displayInsightsPopup" [modal]="true"
  [dismissableMask]="true" (onHide)="closeInsightsPopup()">
  <div class="insights_container">
    <ng-container *ngFor="let data of popupData">
      <div id={{data.id}} class="insights_list">
        <label class="insights_list_label">{{ data.label }}</label>
        <p-checkbox name="appleInsights" value={{data.value}} [(ngModel)]="selectedInsightPrefs"
          (onChange)="prefsChanged()">
        </p-checkbox>
      </div>
    </ng-container>
  </div>

  <!-- Footer -->
  <ng-template pTemplate="footer">
    <div>
      <button pButton label="CANCEL" class="p-button-outlined p-button-secondary"
        (click)="closeInsightsPopup()"></button>
      <button pButton label="SAVE" (click)="saveInsightsPrefs()"></button>
    </div>
  </ng-template>
</p-dialog>
<!-- Footer -->

<!-- Dialog code -->
