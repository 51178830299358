import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DBService } from 'src/app/core/backend-adapter/db.service';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { GbpQaAddLocationComponent } from 'src/app/gbp-management/gbp-tab-qa/components/gbp-qa-add-location/gbp-qa-add-location.component';

@Component({
  selector: 'app-gbp-post-locations',
  templateUrl: './gbp-post-locations.component.html',
  styleUrls: ['./gbp-post-locations.component.scss'],
})
export class GbpPostLocationsComponent implements OnInit, OnDestroy {
  tableData;
  addLocation = false;
  isPagination = false;
  bulkOwner = false;
  id: number;
  offset = 0;
  pageSize = 20;
  paginatorRows = 20;
  paginatorLength = 0;
  loading: boolean;
  whereClaus: any[] = [];

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    private dbService: DBService,
    private notifyService: NotifyService,
    private socketService: SocketService,
  ) {}

  ngOnInit(): void {
    this.tableData = this.dynamicDialogConfig?.data?.locations;
    this.isPagination = Boolean(this.dynamicDialogConfig?.data?.isPagination);
    this.bulkOwner = Boolean(this.dynamicDialogConfig?.data?.bulkOwner);
    this.id = this.dynamicDialogConfig?.data?.id;
  }

  private loadBulkOwnerLocations() {
    this.loading = true;
    this.dbService.QuestionGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      where: `bulkOwner_id = ${this.id}`,
    })
      .then(
        (repl) => {
          if (repl.collection?.length == 0) {
            this.notifyService.error('There are no locations available');
            this.tableData = [];
            this.paginatorLength = 0;
          } else {
            this.tableData = repl.collection;
            this.paginatorLength = repl.totalEntries;
          }
        },
        (err: Error) => {
          this.tableData = [];
          console.warn('Error loading Locations: %j', err);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  onPageChange(event) {
    this.offset = event.first;
    this.pageSize = event.rows;
    this.getAllLocations();
  }


  private getAllLocations(): void {
    this.socketService.sendRequest('get-localpost-detail-coll', {
      offset: this.offset,
      limit: this.pageSize,
      'detailLevel': 3,
      'where': this.whereClaus
     })
    .then(res => {
      if (res['collection']?.length == 0) {
        this.notifyService.error('There are no locations available');
        this.tableData = [];
        this.paginatorLength = 0;
      } else {
        this.tableData = res['collection'].map(d => {
          return {
            ...d,
            attrs: d.attrs === null ? null : JSON.parse(d.attrs),
          }
        });
        this.paginatorLength = res['totalEntries'];
      }
    },
    (err: Error) => {
      this.tableData = [];
      console.warn('Error loading Locations: %j', err);
    })
  }

  updateTableData(event) {
    this.offset = event.first;
    this.whereClaus = this.buildWhereClaus(event.globalFilter || '');
    this.getAllLocations();
  }

  buildWhereClaus(search) {
    const tempFilterArr: string[] = [];
    let whereClauseArray: Array<any> = ['-and', `post_id=${this.id}`];
    const pushQueriesToMainArray = () => {
      whereClauseArray.push(...tempFilterArr);
      tempFilterArr.length = 0;
    };
    if (search) {
      tempFilterArr.push(`l.storeCode::text ~* '\\y${search}'`);
      // tempFilterArr.push(`l.businessName::text ~* '\\y${search}'`);
      pushQueriesToMainArray();
    }
    return whereClauseArray;
  }

  ngOnDestroy(): void {
    if (this.dynamicDialogConfig?.data?.openAddLocation) {
      this.dialogService.open(GbpQaAddLocationComponent, {
        width: '50%',
      });
    }
  }
}
