import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncteReviewsDate',
})

export class TruncateReviewsDatePipe implements PipeTransform {
  transform(value: any): string {
    if(value !== null || value !== undefined) {
      const { _year_, _month_, _day_ } = value;
      return `${_year_}/${_month_}/${_day_}`;
    } else {
      return 'N/A';
    }
  }
}

